import { TrialProsperiLookingFor } from '@/pages/payment/components/purchase/components/purchase-promo/components/trial-prosperi-modal/helpers/type/type';
import { I18n } from '@/services/i18n';

type Message = {
  tryNow: string;
  lookingFor: {
    [key in TrialProsperiLookingFor]: string;
  };
  getPremium50: string;
};

const messages: I18n<Message> = {
  en: {
    tryNow: 'GET PREMIUM',
    lookingFor: {
      serious: 'Serious',
      romantic: 'Romantic',
      flirty: 'Flirty',
      naughty: 'Naughty',
      hookup: 'Hookup',
      relationship: 'Relationship',
      friends: 'Friends',
      skip: 'Casual',
    },
    getPremium50: 'GET -50% PREMIUM',
  },
};

export { messages };
