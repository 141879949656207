<template>
  <ModalPortal :background-scroll="promoModal.isBackgroundScrollable" :is-teleport-enabled="false">
    <Purchase
      purchase="promo"
      :payment-success="success"
      :payment-fail="fail"
      @set-page="setPage"
    />
  </ModalPortal>
</template>

<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: true };
</script>
<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, PropType } from 'vue';

import ModalPortal from '@/components/modal/ModalPortal.vue';
import { PaymentPage } from '@/helpers/gtm-triggers/gtm-triggers';
import Purchase from '@/pages/payment/components/purchase/Purchase.vue';
import { replaceRoute } from '@/router';
import { CommonStatePromoModal } from '@/store/modules/common/common-state';
import { getStore } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

defineProps({
  defaultPackage: {
    required: false,
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
    default: undefined,
  },
});

const emit = defineEmits(['close', 'set-page']);
const promoModal = computed<CommonStatePromoModal>(() => getStore().state.common.promoModal);

function success() {
  close();
  replaceRoute('paymentSuccess');
}

function fail() {
  close();
  replaceRoute('paymentFail');
}

function close() {
  emit('close');
}

function setPage(page: PaymentPage) {
  emit('set-page', page);
}
</script>
