<template>
  <div class="h-full">
    <RouterView />
    <Notification></Notification>
    <BoosterPromoModal></BoosterPromoModal>
    <ModalsContainer class="modals-container" />
    <SupportBtn />
  </div>
</template>

<script lang="ts" setup>
import { NotificationCategory } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/connection/v1/notification_pb';

import BoosterPromoModal from '@/components/booster-promo-modal/BoosterPromoModal.vue';
import { useNotificationActivity } from '@/components/notification/hooks/use-notification-activity/use-notification-activity';
import Notification from '@/components/notification/Notification.vue';
import SupportBtn from '@/components/support-btn/SupportBtn.vue';
import { useUnusedCreditsModal } from '@/components/unused-credits-email-modal/hooks/use-unused-credits-modal/use-unused-credits-modal';
import { toggleActivityFavicon } from '@/helpers/favicon/favicon';
import { trackCameBack } from '@/helpers/track-came-back/track-came-back';
import { trackWebPushReferrer } from '@/helpers/webpush/webpush';
import { handleConversions } from '@/pages/payment/helpers/payment-websocket/helpers/conversion/conversion';
import { CommonStateIndex } from '@/store/modules/common/common-state';
import { Mutation, getStore } from '@/store/store-helper';

getStore().subscribe(({ type, payload }: { type: keyof Mutation; payload: CommonStateIndex }) => {
  if (type === 'mutationCommonSetState') {
    toggleActivityFavicon(payload.newChatMessagesCount > 0 || payload.hasNewActivity);
  }
});

const { trigger } = useNotificationActivity();
trigger(NotificationCategory.USER_INTRO);

handleConversions();

useUnusedCreditsModal().showCreditsPromoPopup();

trackCameBack();
trackWebPushReferrer();
</script>
