import { I18n } from '@/services/i18n';

type Message = {
  unlimitedAccess: string;
  bestValue: string;
  save: string;
  tax: string;
  billedMonthly: string;
};

const messages: I18n<Message> = {
  en: {
    unlimitedAccess: 'UNLIMITED ACCESS',
    bestValue: 'Best value',
    save: 'SAVE',
    tax: 'tax',
    billedMonthly: 'billed monthly',
  },
};

export { messages };
