<template>
  <div
    :class="{
      'popular h-20': pkg.isPopular,
      'active-popular !border-premium border-2': isSelected,
      'h-16': !pkg.isPopular,
    }"
    class="billing_card popular discount best_deal transition-all relative"
    @click="toggleSelect"
  >
    <div class="billing_card-check rounded-full bg-premium">
      <i class="icon icon-success"></i>
    </div>

    <div v-show="pkg.isPopular" class="popular_label">
      {{ t('popular') }}
    </div>

    <div class="billing_card-body ms-1">
      <div class="billing_card-title">{{ pkg.label }}</div>

      <div
        v-if="packagePricePerWeek.price.totalAmount"
        class="billing_card-price-green text-caption text-dark-200 line-through"
      >
        {{ packagePricePerDayFormatted }}
      </div>
    </div>

    <div
      class="ml-auto triangle_label bg-green !absolute right-4 w-24"
      :class="pkg.isPopular ? 'top-[27px]' : '-translate-y-1/2 top-1/2'"
    >
      <div class="triangle"></div>
      <div class="triangle_label-body">
        <div class="flex ms-2 font-semibold">
          <span class="text-caption">{{
            getCurrencySymbol(packagePricePerWeek.price.currency)
          }}</span>
          <span class="text-headline6 mt-[2px]">{{ packagePricePerDayInt }}</span>
          <span class="text-caption">{{ packagePricePerDayCents }}</span>
          <span class="text-overline opacity-80 lowercase font-normal mt-3 -ml-[14px]"
            >per day</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, PropType, reactive } from 'vue';

import { getCurrencySymbol, getFormattedCurrency } from '@/helpers/number';
import { useModel } from '@/hooks/use-model/use-model';
import { usePackagePricePerWeek } from '@/pages/payment/hooks/use-package-price/use-package-price';
import { useI18n } from '@/services/i18n';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const props = defineProps({
  pkg: {
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const { t } = useI18n({ messages });

const selectedModel = useModel<ExtractClassFields<PremiumPackage>>(props, 'modelValue', emit);

const packagePricePerWeek = reactive(usePackagePricePerWeek(props.pkg));

const packagePricePerDayFormatted = computed(() => {
  return getFormattedCurrency(
    packagePricePerWeek.price.baseAmount / 7n,
    packagePricePerWeek.price.currency,
  );
});

const packagePricePerDayInt = computed(() => {
  return Math.trunc(Number(packagePricePerWeek.price.totalAmount / 7n) / 100);
});

const packagePricePerDayCents = computed(() => {
  return Number(packagePricePerWeek.price.totalAmount / 7n) % 100;
});

const isSelected = computed(() => {
  return selectedModel.value.id === props.pkg.id;
});

function toggleSelect(): void {
  selectedModel.value = props.pkg;
}
</script>
