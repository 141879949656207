import { GetExternalUserInfoResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/my_profile_api_pb';
import { ProfileLocation } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';
import { ProfileField } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/update_pb';
import { getLandingUrl } from '@project-gd-x/shared-web/src/helpers/landing-url/landing-url';
import { addQaParamToUrl, isQaEmail } from '@project-gd-x/shared-web/src/helpers/qa/qa';
import { ActionContext } from 'vuex';

import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { getDocumentUrl } from '@/helpers/navigator/navigator';
import { getRereg } from '@/helpers/rereg/rereg';
import { getEngagementProfilesFrom } from '@/pages/signup/store/helpers/signup-helper';
import {
  SignupState,
  SignupStateAuthData,
  SignupStateEngagementProfile,
} from '@/pages/signup/store/signup-state';
import {
  getEngagementProfiles,
  likeEngagementProfiles,
} from '@/services/api/connection/connection-api';
import {
  getExternalUserInfo,
  GetExternalUserInfoArgument,
  signUp,
  SignupArgument,
  updateProfile,
  uploadPhoto,
} from '@/services/api/my-profile/my-profile-api';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { CommonState } from '@/store/modules/common/common-state';
import { ActionFunction, commit } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

declare module '@/store/store-helper' {
  interface Action {
    actionSignup: ActionFunction<SignupArgument, SignupStateAuthData>;
    actionSignupGetEngagementProfiles: ActionFunction<number, SignupStateEngagementProfile[]>;
    actionSignupLikeEngagementProfiles: ActionFunction<SignupStateEngagementProfile[], void>;
    actionSignupGetExternalUserInfo: ActionFunction<
      GetExternalUserInfoArgument,
      ExtractClassFields<GetExternalUserInfoResponse>
    >;
    actionSignupUploadPhoto: ActionFunction<string, void>;
    actionSignupSetMyLocation: ActionFunction<ActionSignupSetMyLocationPayload, void>;
  }
}

type ActionSignupSetMyLocationPayload = {
  location: ExtractClassFields<ProfileLocation>;
  isLocationDetectionAllowed?: boolean;
  isLocationUpdateAllowed?: boolean;
};

export async function actionSignup(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state, rootState }: ActionContext<SignupState, { common: CommonState }>,
  payload: SignupArgument,
): Promise<SignupStateAuthData> {
  let landingUrl: string | null = '';

  try {
    landingUrl = getLandingUrl();
  } catch {
    sentryCaptureMessage({
      message: 'ERROR_PARSE_URL_V2',
      captureContext: {
        extra: {
          error: {
            documentUrl: getDocumentUrl(),
            landingUrl: landingUrl,
          },
        },
      },
    });
  }

  if (landingUrl === null) {
    landingUrl = 'https://meetmyage.com/?gdx_source=null';

    sentryCaptureMessage({
      message: 'ERROR_GET_URL_IS_NULL_V2',
      captureContext: {
        extra: {
          error: {
            documentUrl: getDocumentUrl(),
          },
        },
      },
    });
  }

  try {
    if (isQaEmail(payload.email)) {
      landingUrl = addQaParamToUrl(landingUrl);
    }
  } catch {
    sentryCaptureMessage({
      message: 'ERROR_DETECTING_QA_EMAIL_V2',
      captureContext: {
        extra: {
          error: {
            landingUrl,
          },
        },
      },
    });
  }

  const rereg = getRereg();
  const response = await signUp(payload, {
    'Landing-Url': landingUrl ?? '',
    Rr: rereg ?? '',
  });

  if (rereg) {
    customEventTrigger({
      event: 'signup_rr',
      rereg,
    });
  }

  return {
    accessToken: response.accessToken?.token ?? '',
    refreshToken: response.refreshToken?.token ?? '',
    userId: response.userId,
  };
}

export async function actionSignupGetEngagementProfiles(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<SignupState, unknown>,
  limit: number,
): Promise<SignupStateEngagementProfile[]> {
  const response = await getEngagementProfiles(limit);
  return getEngagementProfilesFrom(response);
}

export async function actionSignupLikeEngagementProfiles(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<SignupState, unknown>,
  profiles: SignupStateEngagementProfile[],
): Promise<void> {
  await likeEngagementProfiles(profiles);
}

export async function actionSignupGetExternalUserInfo(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<SignupState, unknown>,
  payload: GetExternalUserInfoArgument,
): Promise<ExtractClassFields<GetExternalUserInfoResponse>> {
  return await getExternalUserInfo(payload);
}

export async function actionSignupUploadPhoto(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<SignupState, unknown>,
  photoId: string,
): Promise<void> {
  await uploadPhoto(photoId);
  commit('mutationSignupAddMyPhoto', photoId);
}

export async function actionSignupSetMyLocation(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<SignupState, unknown>,
  payload: ActionSignupSetMyLocationPayload,
): Promise<void> {
  const isLocationDetectionAllowed = payload.isLocationDetectionAllowed ?? false;
  const isLocationUpdateAllowed = payload.isLocationUpdateAllowed ?? false;

  commit('mutationSignupSetMyLocation', payload.location);
  commit('mutationSignupSetIsLocationDetectionAllowed', isLocationDetectionAllowed);
  commit('mutationSignupSetIsLocationUpdateAllowed', isLocationUpdateAllowed);

  const location = new ProfileLocation({
    city: payload.location.city,
    country: payload.location.country,
    state: payload.location.state,
    latitude: payload.location.latitude,
    longitude: payload.location.longitude,
  });
  const locationField = new ProfileField({
    field: {
      case: 'location',
      value: location,
    },
  });

  const isLocationUpdateAllowedField = new ProfileField({
    field: {
      case: 'isLocationUpdateAllowed',
      value: isLocationUpdateAllowed,
    },
  });

  const isLocationDetectionAllowedField = new ProfileField({
    field: {
      case: 'isLocationDetectionAllowed',
      value: isLocationDetectionAllowed,
    },
  });

  await updateProfile([
    locationField,
    isLocationUpdateAllowedField,
    isLocationDetectionAllowedField,
  ]);
}
