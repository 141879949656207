import { I18n } from '@/services/i18n';

type Message = {
  thanks: string;
  great: string;
  enjoy: string;
  tap: string;
  notNow: string;
  cancel: string;
  submit: string;
};

const messages: I18n<Message> = {
  en: {
    thanks: 'Thanks for submitting a review!',
    great: 'Great',
    enjoy: 'Enjoying MeetMyAge?',
    tap: 'Tap a star to rate',
    notNow: 'Not Now',
    cancel: 'Cancel',
    submit: 'Submit',
  },
};

export { messages };
