import { I18n } from '@/services/i18n';

type Message = {
  upgrade: string;
  cancel: string;
  availableAfter: string;
  youCanSend: string;
  sendAnyway: string;
};

const messages: I18n<Message> = {
  en: {
    upgrade: 'Upgrade',
    cancel: 'Cancel',
    availableAfter: 'Available after',
    youCanSend: 'You can send this gift for free only<br />once a day.',
    sendAnyway: 'Send anyway',
  },
};

export { messages };
