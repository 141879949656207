<template>
  You will be charged {{ price }}.
  <span>
    You can cancel your trial anytime you want in account settings. By tapping
    <span v-html="btnText"></span>, you agree that if you don’t cancel at least 24 hours before the
    end of the {{ label }} introductory period, you will automatically be charged the total price of
    {{ priceNext }} every {{ label }}. To cancel subscription, write to
    <a class="" href="mailto:support@meetmyage.com">support@meetmyage.com</a>. Please visit our
  </span>
  <a @click.prevent="self.membershipModal.showModal()"> Membership and Refund Policy </a>
  for more details. Also, you can manage your subscription in your account/profile settings
</template>

<script lang="ts" setup>
import { useSelf } from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-policy-trial/hooks/use-self/use-self';

defineProps({
  label: {
    type: String,
    required: true,
  },
  price: {
    type: String,
    required: true,
  },
  priceNext: {
    type: String,
    required: true,
  },
  btnText: {
    type: String,
    default: 'Submit',
  },
});

const self = useSelf({});
</script>

<style scoped>
a {
  font-size: inherit;
}
</style>
