import { computed, ComputedRef, ref } from 'vue';

import { subscribeRouteUntilAuth } from '@/helpers/auth/auth';
import {
  getLastLoginTimestamp,
  hasFullDayPassed,
  updateLastLoginTimestamp,
} from '@/helpers/last-login-timestamp/last-login-timestamp';
import { getCache, removeCache } from '@/helpers/persistent-cache';
import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { useModal } from '@/services/modal/modal';
import { dispatch } from '@/store/store-helper';

import UnusedCreditsEmailModal from '../../UnusedCreditsEmailModal.vue';

let status: PaymentStateSubscription;
const isHidden = ref(false);
const creditsBalance = ref(0);
const buttonCache = ref();
const CACHE_KEY = 'unused_credits_button_hidden';

export function useUnusedCreditsModal(): {
  isExpired: ComputedRef<boolean>;
  hasUnusedCredits: ComputedRef<boolean>;
  isPromoModalVisible: ComputedRef<boolean>;
  isPromoButtonVisible: ComputedRef<boolean>;
  showCreditsPromoPopup: () => void;
  hidePromo: () => void;
  showModal: () => void;
} {
  const isFullDay: boolean = hasFullDayPassed(getLastLoginTimestamp());
  const isExpired = computed<boolean>(() => {
    if (status.subscription?.state.case === 'inactive') {
      if (status.subscription?.state.value.expiresTime) {
        const expiresTime = Number(status.subscription?.state.value?.expiresTime?.seconds ?? 0);
        const currentTime = +new Date();
        // 24 hours
        const ms = 86_400_000;

        return currentTime - expiresTime >= ms;
      } else {
        return true;
      }
    }

    return false;
  });

  const hasUnusedCredits = computed<boolean>(() => creditsBalance.value >= 1);

  const isPromoVisible = computed<boolean>(() => hasUnusedCredits.value && isExpired.value);

  const isPromoModalVisible = computed<boolean>(() => {
    return isFullDay && !isHidden.value && isPromoVisible.value;
  });

  const isPromoButtonVisible = computed<boolean>(() => {
    return isPromoVisible.value && !buttonCache.value && !isHidden.value;
  });

  function resolveButtonCache(): void {
    buttonCache.value = getCache(CACHE_KEY);
  }

  async function loadSubscriptionStatus(): Promise<void> {
    if (!status) {
      status = await dispatch('actionPaymentGetSubscriptionStatus');
    }
  }

  async function loadCreditsCount(): Promise<void> {
    const { creditsCount: count } = await dispatch('actionPaymentGetCreditsBalance');

    creditsBalance.value = Number(count);
  }

  function resolvePromoButtonVisibility(): void {
    if (isPromoVisible.value && isFullDay) {
      removeCache(CACHE_KEY);
    }

    resolveButtonCache();
  }

  function showCreditsPromoPopup(): void {
    const run = async (): Promise<void> => {
      await loadSubscriptionStatus();
      await loadCreditsCount();
      resolvePromoButtonVisibility();

      if (isPromoModalVisible.value) {
        showModal();
      }
    };

    subscribeRouteUntilAuth(run);
  }

  function hidePromo(): void {
    updateLastLoginTimestamp();
    isHidden.value = true;
    hideModal();
  }

  const { showModal, hideModal } = useModal(() => ({
    component: UnusedCreditsEmailModal,
    props: {
      credits: creditsBalance,
    },
  }));

  return {
    isExpired,
    hasUnusedCredits,
    isPromoModalVisible,
    isPromoButtonVisible,
    showCreditsPromoPopup,
    hidePromo,
    showModal,
  };
}
