import {
  CreditsPackage,
  PremiumPackage,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';

import {
  CreditsLastAction,
  PaymentState,
  PaymentStateBillingHistoryItem,
  PaymentStateCard,
  PaymentStateFail,
  PaymentStateSolidPaymentForm,
  PaymentStateSubscribed,
  PaymentStateSubscription,
  PaymentStateSuccess,
} from '@/pages/payment/store/payment-state';
import { ExtractClassFields } from '@/type';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationPaymentSetPremiumPackages: ExtractClassFields<PremiumPackage>[];
    mutationPaymentSetCreditsPackages: ExtractClassFields<CreditsPackage>[];
    mutationPaymentSetPromoCreditsPackages: ExtractClassFields<CreditsPackage>[];
    mutationPaymentSetSolidPaymentForm: PaymentStateSolidPaymentForm;
    mutationPaymentSetPaymentSuccess: PaymentStateSuccess;
    mutationPaymentSetPaymentFail: Partial<PaymentStateFail>;
    mutationPaymentSetSubscribed: PaymentStateSubscribed;
    mutationPaymentAddBillingHistory: PaymentStateBillingHistoryItem[];
    mutationPaymentClearBillingHistory: never;
    mutationPaymentSetCreditsCount: number;
    mutationPaymentSetLastPurchasedPackage:
      | ExtractClassFields<PremiumPackage>
      | ExtractClassFields<CreditsPackage>;
    mutationPaymentEnableCardMethod: never;
    mutationPaymentEnableGooglePayMethod: never;
    mutationPaymentEnableApplePayMethod: never;
    mutationPaymentEnableSolidPaypalMethod: never;
    mutationPaymentSetPaymentMethodLoading: boolean;
    mutationPaymentSetPaymentCards: PaymentStateCard[];
    mutationPaymentSetDefaultCard: PaymentStateCard;
    mutationPaymentSetSubscriptionStatus: PaymentStateSubscription;
    mutationPaymentSetSubscriptionRenewStatus: boolean;
    mutationPaymentSetFreeGiftsCount: number;
    mutationPaymentSetCreditsLastAction: CreditsLastAction;
  }
}

export function mutationPaymentSetCreditsLastAction(
  state: PaymentState,
  payload: CreditsLastAction,
) {
  state.creditsLastAction = payload;
}

export function mutationPaymentSetSubscriptionRenewStatus(state: PaymentState, status: boolean) {
  state.isRenewed = status;
}

export function mutationPaymentSetFreeGiftsCount(state: PaymentState, count: number) {
  state.freeGiftsCount = count;
}

export function mutationPaymentSetSubscriptionStatus(
  state: PaymentState,
  status: PaymentStateSubscription,
) {
  state.subscriptionStatus = status;
}

export function mutationPaymentSetPremiumPackages(
  state: PaymentState,
  packages: ExtractClassFields<PremiumPackage>[],
) {
  state.premiumPackages = packages;
}

export function mutationPaymentSetCreditsPackages(
  state: PaymentState,
  packages: ExtractClassFields<CreditsPackage>[],
) {
  state.creditsPackages = packages;
}

export function mutationPaymentSetPromoCreditsPackages(
  state: PaymentState,
  packages: ExtractClassFields<CreditsPackage>[],
) {
  state.promoCreditsPackages = packages;
}

export function mutationPaymentSetSolidPaymentForm(
  state: PaymentState,
  solidPaymentForm: PaymentStateSolidPaymentForm,
) {
  state.solidPaymentForm = solidPaymentForm;
}

export function mutationPaymentSetSubscribed(
  state: PaymentState,
  subscribed: PaymentStateSubscribed,
) {
  state.subscribed = subscribed;
}

export function mutationPaymentSetPaymentSuccess(
  state: PaymentState,
  success: PaymentStateSuccess,
) {
  state.paymentSuccess = success;
}

export function mutationPaymentSetPaymentFail(
  state: PaymentState,
  fail: Partial<PaymentStateFail>,
) {
  state.paymentFail = { ...state.paymentFail, ...fail };
}

export function mutationPaymentAddBillingHistory(
  state: PaymentState,
  history: PaymentStateBillingHistoryItem[],
) {
  state.billingHistory.push(...history);
}

export function mutationPaymentClearBillingHistory(state: PaymentState) {
  state.billingHistory = [];
}

export function mutationPaymentSetCreditsCount(state: PaymentState, creditsCount: number) {
  // Prevent displaying negative balance for user. It's useful if credits autorefill is enabled.
  if (creditsCount >= 0) {
    state.creditsCount = creditsCount;
  }
}

export function mutationPaymentSetLastPurchasedPackage(
  state: PaymentState,
  pkg: ExtractClassFields<PremiumPackage> | ExtractClassFields<CreditsPackage>,
) {
  state.lastPurchasedPackage = pkg;
}

export function mutationPaymentEnableCardMethod(state: PaymentState) {
  state.paymentMethods.card = {
    isAvailable: true,
  };
}

export function mutationPaymentEnableGooglePayMethod(state: PaymentState) {
  state.paymentMethods.googlePay = {
    isAvailable: true,
  };
}

export function mutationPaymentEnableApplePayMethod(state: PaymentState) {
  state.paymentMethods.applePay = {
    isAvailable: true,
  };
}

export function mutationPaymentEnableSolidPaypalMethod(state: PaymentState) {
  state.paymentMethods.solidPaypal = {
    isAvailable: true,
  };
}

export function mutationPaymentSetPaymentMethodLoading(state: PaymentState, isLoading: boolean) {
  state.isPaymentMethodsLoading = isLoading;
}

export function mutationPaymentSetPaymentCards(
  state: PaymentState,
  paymentCards: PaymentStateCard[],
) {
  state.paymentCards = paymentCards;
}

export function mutationPaymentSetDefaultCard(state: PaymentState, card: PaymentStateCard) {
  const index = state.paymentCards.findIndex((item) => item.id === card.id);

  state.paymentCards.splice(index, 1);
  state.paymentCards.unshift(card);
}
