<template>
  <div class="h-full bg-premium flex flex-col relative overflow-auto">
    <div
      class="fixed max-w-screen-xs mx-auto left-0 right-0 text-center h-14 flex items-center justify-center bg-premium z-10"
    >
      <a
        href="#"
        data-test-id="btn_close"
        class="text-white absolute left-6 opacity-50"
        @click.prevent="emit('close')"
        ><i class="icon icon-close"></i
      ></a>
      <span class="text-tag text-white opacity-50">Activate Premium</span>
    </div>

    <div class="px-6">
      <PremiumSlider :my-profile-full="myProfileFull" :feature="feature" />
    </div>

    <div class="bg-white flex-grow rounded-t-2xl px-6">
      <!-- Timer start -->
      <div
        class="flex items-center my-4 transition-all duration-500 ease-in-out overflow-hidden"
        :class="self.timer.isActive ? 'h-14' : 'h-0 '"
        data-test-id="timer_block_top_prp"
      >
        <div class="text-headline6 font-semibold flex-grow flex items-center justify-start">
          Discount expires in
        </div>
        <div class="flex text-headline4 text-error font-semibold text-center gap-1 font-mono">
          <div>
            {{ self.timer.timeLeftFormatted.mm }}
            <div class="text-overline text-dark-200 font-medium">min</div>
          </div>
          <div>:</div>
          <div>
            {{ self.timer.timeLeftFormatted.ss }}
            <div class="text-overline text-dark-200 font-medium">sec</div>
          </div>
        </div>
      </div>
      <!-- Timer end -->

      <!-- Premium items start -->
      <div v-if="self.packageSelected">
        <PremiumItem
          v-for="pkg in self.packageList"
          :key="pkg.id"
          v-model="self.packageSelected"
          :pkg="pkg"
        />
      </div>
      <!-- Premium items end -->

      <div class="text-body1 text-dark-200 text-center my-2">
        <img src="./assets/img/ic_security.svg?url" alt="" /> Pay safe and secure
      </div>
      <div class="text-center">
        <img class="w-[250px]" src="./assets/img/pay-safe.png" width="500" alt="" />
      </div>

      <div class="text-overline text-dark-200 font-medium text-center mt-2 mb-28">
        By clicking “GET -50% PREMIUM” you agree to enroll in a
        {{ self.packageSelected?.label }} subscription to https://meetmyage.com/ service. The
        {{ self.packageSelected?.label }} trial costs {{ self.packageSelectedPriceFormatted }}. If
        you don’t cancel during your {{ self.packageSelected?.label }} trial, your subscription will
        automatically renew until you cancel and you will be billed
        {{ self.packageSelectedPriceNextFormatted }} every {{ self.packageSelected?.label }}.
        Payments will be charged from the card you specified here. You can cancel your subscription
        by contacting our customer support team via email at
        <a
          class="text-overline text-dark-200 font-medium underline"
          href="mailto:support@meetmyage.com"
          >support@meetmyage.com</a
        >.
        <a
          class="text-overline text-dark-200 font-medium underline"
          href="#"
          @click="self.textPageModal.showModal('membership')"
          >Membership and Refund Policy</a
        >.
      </div>
    </div>

    <div
      class="fixed max-w-screen-xs mx-auto left-0 right-0 bottom-0 h-[72px] bg-white px-6 pt-2 border-t"
    >
      <PurchaseContinue
        :is-autorefill-visible="false"
        btn-class="!bg-premium text-white"
        @select="emit('select', self.packageSelected)"
        @next="emit('next', self.packageSelected)"
      >
        <template #continue> GET -50% PREMIUM </template>
      </PurchaseContinue>
      <!--      <PrimaryBlockButton class="!bg-premium" @click="emit('next', self.packageSelected)"-->
      <!--        >GET -50% PREMIUM</PrimaryBlockButton-->
      <!--      >-->
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted, PropType } from 'vue';

import PremiumItem from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-item/PremiumItem.vue';
import PremiumSlider from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-slider/PremiumSlider.vue';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { MyProfileStateFull } from '@/pages/my-profile/store/my-profile-state';
import PurchaseContinue from '@/pages/payment/components/layout/components/purchase-continue/PurchaseContinue.vue';

import { useSelf } from './hooks/use-self/use-self';

const props = defineProps({
  feature: {
    type: String as PropType<SubscriptionFeature>,
    default: undefined,
  },
  source: {
    type: String as PropType<PromoShowPurchaseSource>,
    required: true,
  },
  myProfileFull: {
    type: Object as PropType<MyProfileStateFull>,
    required: true,
  },
});

const emit = defineEmits(['next', 'close', 'select']);

const self = useSelf({
  source: props.source,
});

self.init();

onUnmounted(() => {
  self.destroy();
});
</script>
