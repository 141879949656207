import { I18n } from '@/services/i18n';

type Message = {
  expired: string;
  upgrade: string;
  wouldYouLikeToTakeExpired: string;
};

const messages: I18n<Message> = {
  en: {
    expired: 'Your {tier}<br />membership has expired',
    upgrade: 'Upgrade',
    wouldYouLikeToTakeExpired:
      'Would you like to take care of it and prolong now to keep using the platform?',
  },
};

export { messages };
