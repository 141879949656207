import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { I18n } from '@/services/i18n';

type Message = {
  slider: Record<
    SubscriptionFeature,
    {
      header: string;
      content: string;
    }
  >;

  advancedSearchChatRequestHeader: string;
  advancedSearchChatRequestContent: string;
};

const messages: I18n<Message> = {
  en: {
    slider: {
      unlimitedLikes: {
        header: 'Daily Top Profile',
        content: 'More likes = more chances to meet someone special',
      },
      advancedSearch: {
        header: '3 Daily Power-Likes',
        content: 'Power-Like people of your interest and stay in touch with them',
      },
      unblockUsers: {
        header: '2 Dates Daily',
        content: 'Donate subscription to stay in touch with the person that you like',
      },
      unlimitedMessages: {
        header: 'Everyday 200 Credits Bonus',
        content: 'Spend credits by sending gifts to people you like or get TOP profile',
      },
      deleteOwnMessages: {
        header: '24/7 Support',
        content: 'Get instant Support and get answers to all of your questions immediately',
      },
      advancedInformation: {
        header: 'Personal Dating-Assistant',
        content: 'Unlock advanced profiles description and see all photos of the profile',
      },
      activity: {
        header: 'Full Premium',
        content: 'Get unlimited access to all profile info of other singles and more',
      },
      ads: {
        header: 'No Ads',
        content: 'Tired of ads? Upgrade and don’t get bothered by advertising',
      },
    },
    advancedSearchChatRequestHeader: '3 Chat Requests every day',
    advancedSearchChatRequestContent:
      'Send Chat Requests to people of your interest and stay in touch with them',
  },
};

export { messages };
