import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  description: string;
  action: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Check astrological compatibility',
    description: 'This person might be your destiny. How about checking what the stars will say?',
    action: 'Check compatibility',
  },
};

export { messages };
