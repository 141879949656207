import { I18n } from '@/services/i18n';

type Message = {
  text: string;
  refresh: string;
};

const messages: I18n<Message> = {
  en: {
    text: 'New website updates were released. To keep using MeetMyAge, click the button below or refresh the page.',
    refresh: 'Refresh',
  },
};

export { messages };
