import { GetFacebookTrackingParamsResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/my_profile_api_pb';

import { getUserId } from '@/helpers/auth/auth';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { isParamT } from '@/helpers/navigator/navigator';
import { hasCache, setCache } from '@/helpers/persistent-cache';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { dispatch, getState } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

declare function fbq(...args: unknown[]): void;
declare global {
  interface Window {
    fbq?: typeof fbq;
  }
}

type FacebookTrackEvents = {
  account_created: {
    type: 'facebook' | 'email' | 'google';
  };
  profile_created: never;
  Trial_3ds: never;
  Payers_3ds: never;
  take_trial: never;
  take_trial_2: never;
  take_premium: never;
  StartTrial: never; // Standard
  Purchase: { value: number; currency: string }; // Standard
  Subscribe: never; // Standard
  Payer: never;
  subscribed: never;
  photo_upload: never;
};

export type FacebookTrackEventName = keyof FacebookTrackEvents;

const CACHE_PREFIX = 'facebook_event_';
export const PIXEL_ID_CACHE_KEY = 'fpi';

// Standard events: https://developers.facebook.com/docs/meta-pixel/reference#standard-events
const standardEvents: FacebookTrackEventName[] = ['StartTrial', 'Subscribe', 'Purchase'];

function getEventId(event: FacebookTrackEventName) {
  return getUserId() + event;
}

const eventBlockedList: FacebookTrackEventName[] = [
  'take_trial_2',
  'Trial_3ds',
  'subscribed',
  'Payer',
  'Payers_3ds',
];

export async function trackFacebookPixel<
  K extends FacebookTrackEventName,
  T extends FacebookTrackEvents[K],
>({ event, params, eventId }: { event: K; params?: T; eventId?: string; orderId?: string }) {
  if (isParamT(getState().common.index.landingUrl, 'm1b')) {
    if (eventBlockedList.includes(event)) {
      console.log('FB pixel event is blocked', event, params ?? {});
      return;
    }
  }

  const isSafe = await dispatch('actionCommonGetIsSafe', event);

  if (!isSafe) return;

  const cacheKey = CACHE_PREFIX + event;
  const hasEvent = hasCache(cacheKey);

  // Prevent sending event duplicates https://gdx.myjetbrains.com/youtrack/issue/GDP-624
  if (!hasEvent) {
    // Generate eventID if it was not provided
    // More about eventID: https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events/
    const eventID = eventId ?? getEventId(event);

    if (eventID) {
      const method = standardEvents.includes(event) ? 'track' : 'trackCustom';
      // if (!landingUrl) {
      //   try {
      //     const resp = await dispatch('actionGetState');
      //     landingUrl = resp.landingUrl;
      //   } catch {
      //     landingUrl = '';
      //   }
      // }

      // if (!isUnknownStandardUrl(landingUrl)) {
      if (typeof window.fbq !== 'undefined') {
        window.fbq(method, event, params ?? {}, { eventID });
      } else {
        console.log('FB pixel is disabled on dev', method, event, params ?? {}, { eventID });
      }

      try {
        customEventTrigger({
          event: 'facebook_pixel_' + event,
        });
      } catch {
        console.log('Error: realtime facebook tracking');
      }

      setCache(cacheKey, '1');
    } else {
      sentryCaptureMessage({
        message: 'facebook_pixel eventID is not specified',
        captureContext: { extra: { error: { event } } },
      });
    }
  }
}

export function getPixelId(landingUrl: string) {
  let pixelId = '2754010231487281';

  if (isParamT(landingUrl, '3dm3')) {
    // https://gdx.myjetbrains.com/youtrack/issue/GDP-3697/Frontend-New-pixel-request-3dm3
    pixelId = '1029900251889942';
  } else if (isParamT(landingUrl, 'm1b')) {
    // https://gdx.myjetbrains.com/youtrack/issue/GDP-3724/Frontend-New-Pixel-7d-event-upload-Predictive-LTV
    pixelId = '803528655077337';
  } else if (isParamT(landingUrl, 'ntf3')) {
    // https://gdx.myjetbrains.com/youtrack/issue/GDP-3681/NTF-New-Pixel-90d-event-upload-Predictive-LTV
    pixelId = '803528655077337';
  } else if (isParamT(landingUrl, '3dm')) {
    // https://gdx.myjetbrains.com/youtrack/issue/GDP-3386/New-pixel-request-3dm
    pixelId = '636734405025538';
  } else if (isParamT(landingUrl, 't50')) {
    // 1236388360317819 - t50 funnel pixel
    pixelId = '1236388360317819';
  } else if (isParamT(landingUrl, 'etf')) {
    // 3544278949139683 - expensive trial funnel pixel
    pixelId = '3544278949139683';
  }

  return pixelId;
}

let scriptParams: ExtractClassFields<GetFacebookTrackingParamsResponse> | undefined = undefined;
export async function loadFacebookScript(landingUrl: string) {
  try {
    const pixelId = getPixelId(landingUrl);
    if (scriptParams === undefined && +import.meta.env.VUE_APP_IS_FACEBOOK_PIXEL_ENABLED) {
      scriptParams = await dispatch('actionCommonGetFacebookTrackingParams');

      /////////////////////////////////////////////////////////////////////////
      // Type hell cause it's vendor's script and I have no idea about types //
      /////////////////////////////////////////////////////////////////////////
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !(function (f, b, e, v) {
        if (f.fbq) return;
        const n = (f.fbq = function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line prefer-spread,prefer-rest-params
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!f._fbq) f._fbq = n;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        n.push = n;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        n.loaded = !0;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        n.version = '2.0';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        n.queue = [];
        const t = b.createElement(e) as HTMLScriptElement;
        t.async = !0;
        t.src = v;
        const s = b.getElementsByTagName(e)[0];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      const pixelParams = {
        external_id: scriptParams.externalId,
        em: scriptParams.em,
        ge: scriptParams.ge,
        ct: scriptParams.ct,
        st: scriptParams.st,
        country: scriptParams.country,
        db: scriptParams.db,
      };

      fbq('init', pixelId, Object.assign({}, pixelParams));
      fbq('track', 'PageView');
    }
    setCache(PIXEL_ID_CACHE_KEY, pixelId);
  } catch (e) {
    console.log('Facebook init failed:', e);
  }
}
