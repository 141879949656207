import { I18n } from '@/services/i18n';

type Message = {
  headerPerWeek: string;
  trialOfferUnlocked: string;
  continue: string;
};

const messages: I18n<Message> = {
  en: {
    headerPerWeek: '<br />PREMIUM ONLY {price}',
    trialOfferUnlocked: 'SPECIAL OFFER UNLOCKED',
    continue: 'GET -50% PREMIUM',
  },
};

export { messages };
