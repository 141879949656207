<template>
  <div class="mb-6 mt-16 overflow-hidden" :class="'swiper-gallery-container' + self.id">
    <div class="swiper-wrapper flex">
      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">Advanced information</div>
          <div class="text-white text-caption font-medium">
            See all photos and full <br />
            profiles of other singles
          </div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/prem.svg?url" alt="" />
        </div>
      </div>

      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">Unlimited Likes</div>
          <div class="text-white text-caption font-medium">
            Now you can send only <br />
            3&nbsp;likes daily
          </div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/heart.svg?url" alt="" />
        </div>
      </div>

      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">Advanced filters</div>
          <div class="text-white text-caption font-medium">
            Use unlimited filters to find <br />
            your type of person
          </div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/search.svg?url" alt="" />
        </div>
      </div>

      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">Unlimited chatting</div>
          <div class="text-white text-caption font-medium">
            No limits on chatting and <br />
            messaging
          </div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/unlim.svg?url" alt="" />
        </div>
      </div>

      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">See who likes you</div>
          <div class="text-white text-caption font-medium">
            Your Activity is blured, unlock <br />
            it to meet everyone
          </div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/eye.svg?url" alt="" />
        </div>
      </div>

      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">Boost your profile</div>
          <div class="text-white text-caption font-medium">
            Boost your profile and get X5 <br />
            visits and X2 likes
          </div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/boost.svg?url" alt="" />
        </div>
      </div>

      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">No Ads</div>
          <div class="text-white text-caption font-medium">
            Tired of ads? Upgrade and <br />
            don’t get bothered by <br />
            advertising
          </div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/ads.svg?url" alt="" />
        </div>
      </div>
    </div>
    <div
      class="mt-4 flex gap-1 swiper-bullets"
      :class="'swiper-gallery-pagination' + self.id"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import 'swiper/swiper-bundle.css';

import Swiper, { Autoplay, Pagination } from 'swiper';
import { onMounted, PropType } from 'vue';

import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { MyProfileStateFull } from '@/pages/my-profile/store/my-profile-state';

import { useSelf } from './hooks/use-self/use-self';

Swiper.use([Pagination, Autoplay]);

const props = defineProps({
  feature: {
    type: String as PropType<SubscriptionFeature>,
    default: undefined,
  },
  myProfileFull: {
    type: Object as PropType<MyProfileStateFull>,
    required: true,
  },
});

const self = useSelf({
  feature: props.feature,
});

onMounted(() => {
  self.init();
});
</script>

<style lang="scss">
.swiper-bullets {
  .active {
    opacity: 1;
  }
}
</style>
