<template>
  <RetinaImage
    :image-id="imageId"
    :is-blurred="isBlurred"
    :width="width"
    :height="height"
    :img-width="imgWidth"
    :img-height="imgHeight"
    :format="format"
    :alt="alt"
    :group-ratio="groupRatio"
    :class="noPhotoClassName"
  />
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue';

import RetinaImage from '@/components/retina-image/RetinaImage.vue';
import { ImageFormat, ImageGroupRatio } from '@/services/image';
import { SETTINGS_NO_PHOTO_ID } from '@/settings';

const props = defineProps({
  photoId: {
    type: String,
    required: true,
  },
  isBlurred: {
    type: Boolean,
    required: false,
    default: false,
  },
  width: {
    type: Number,
    required: false,
    default: 0,
  },
  height: {
    type: Number,
    required: false,
    default: 0,
  },
  alt: {
    type: String,
    required: false,
    default: '',
  },
  format: {
    type: String as PropType<ImageFormat>,
    required: false,
    default: 'jpeg',
  },
  groupRatio: {
    type: String as PropType<ImageGroupRatio>,
    required: false,
    default: '',
  },
  imgWidth: {
    type: String,
    required: false,
    default: '',
  },
  imgHeight: {
    type: String,
    required: false,
    default: '',
  },
});

const imageId = computed<string>(() =>
  props.photoId === '' ? SETTINGS_NO_PHOTO_ID : props.photoId,
);

const noPhotoClassName = computed(() => (props.photoId === '' ? 'no-photo' : ''));
</script>
