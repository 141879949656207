<template>
  <ModalPortal :is-teleport-enabled="false" :background-scroll="false">
    <div class="modal-wrapper modal-backdrop modal_row" style="display: block">
      <div class="modal-dialog modal-dialog-full h-full" role="document">
        <div class="modal-content h-full p-6 pt-4 overflow-y-auto" data-test-id="prosperi_popup">
          <div class="flex flex-col relative w-full h-full">
            <div class="flex-1">
              <div>
                <img
                  data-test-id="close_btn_prp"
                  class="mb-4"
                  src="../../../../assets/img/ic_close.svg?url"
                  width="24"
                  alt="close"
                  @click="emit('close')"
                />
              </div>

              <!-- Counter start -->
              <FadeTransition>
                <div v-if="self.timer.isActive" class="flex mb-4 h-16">
                  <div class="flex flex-col items-start" data-test-id="timer_block_top_prp">
                    <div class="text-strongcaption font-medium">Discount expires in</div>
                    <div
                      class="flex text-headline5 text-error font-semibold text-center gap-1 font-mono"
                    >
                      <div>
                        {{ self.timer.timeLeftFormatted.mm }}
                        <div class="text-overline text-dark-200 font-medium">min</div>
                      </div>
                      <div>:</div>
                      <div>
                        {{ self.timer.timeLeftFormatted.ss }}
                        <div class="text-overline text-dark-200 font-medium">sec</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-1 justify-end items-center">
                    <SpinnerButton
                      v-if="self.cardDefault"
                      v-model:is-loading="self.isButtonLoading"
                      data-test-id="try_btn_top_prp"
                      :loading-duration-ms="10000"
                      class="btn h-12 w-40 btn-premium"
                      @click="self.purchasePkg()"
                    >
                      {{ t('tryNow') }}
                    </SpinnerButton>
                    <PrimaryButton
                      v-else
                      data-test-id="try_btn_top_prp"
                      class="h-12 w-40 btn-premium"
                      @click="self.purchasePkg()"
                    >
                      {{ t('tryNow') }}
                    </PrimaryButton>
                  </div>
                </div>
              </FadeTransition>
              <!-- Counter end -->

              <template v-if="type === 'like'">
                <h1
                  data-test-id="title_prp"
                  class="text-headline6 text-error font-semibold text-center"
                >
                  You've run out of likes
                </h1>
                <h2
                  data-test-id="subtitle_prp"
                  class="text-headline7 text-dark font-medium text-center"
                >
                  Get Premium with 50% off and you will:
                </h2>

                <ul
                  class="list-image-checkmarkGreen mt-4 pl-5 text-strongcaption text-dark font-medium"
                >
                  <li class="mb-3 pl-2">
                    Get unlimited likes: supercharge your chances to find a match
                  </li>
                  <li class="mb-3 pl-2">Unlock all her photos and access premium search filters</li>
                  <li class="pl-2">
                    Capture the top spot: rise above other men and be the first in search results
                  </li>
                </ul>
              </template>
              <template v-else-if="type === 'message'">
                <h1
                  data-test-id="title_prp"
                  class="text-headline6 text-error font-semibold text-center"
                >
                  You've run out of messages
                </h1>
                <h2
                  data-test-id="subtitle_prp"
                  class="text-headline7 text-dark font-medium text-center"
                >
                  Get Premium with 50% off and you will:
                </h2>

                <ul
                  data-test-id="description_prp"
                  class="list-image-checkmarkGreen mt-4 pl-5 text-strongcaption text-dark font-medium"
                >
                  <li class="mb-3 pl-2">
                    Get unlimited messages - engage with her and keep the spark alive
                  </li>
                  <li class="mb-3 pl-2">
                    Uncover when she's thinking of you — get notified when she views your profile
                  </li>
                  <li class="pl-2">
                    Take control: 96% agree that the delete own messages function saved their
                    connection
                  </li>
                </ul>
              </template>

              <template v-else>
                <h1 class="text-headline6 text-dark font-semibold text-center">
                  Get Premium with 50% off <br />
                  and&nbsp;you will:
                </h1>

                <ul
                  class="list-image-checkmarkGreen mt-4 pl-5 text-strongcaption text-dark font-medium"
                >
                  <li class="mb-3 pl-2">Unlock all her photos and access premium search filters</li>
                  <li class="mb-3 pl-2">
                    Capture the top spot: rise above other men and be the first in search results
                  </li>
                  <li class="pl-2">
                    Get unlimited messages - engage with her and keep the spark alive
                  </li>
                </ul>
              </template>

              <div class="flex gap-2 mt-4">
                <div
                  data-test-id="looking_for_prp"
                  class="flex-1 rounded-lg bg-dark-500 p-2 text-center"
                >
                  <div class="text-overline text-dark-100 font-medium">Looking for something</div>
                  <div class="text-headline7 text-dark font-medium mt-1">
                    ❤️ {{ t('lookingFor.' + self.lookingFor.toLowerCase()) }}
                  </div>
                </div>
                <div data-test-id="age_prp" class="flex-1 rounded-lg bg-dark-500 p-2 text-center">
                  <div class="text-overline text-dark-100 font-medium">Your age</div>
                  <div class="text-headline7 text-dark font-medium mt-1">
                    🔥 {{ myProfileFull.age }}
                  </div>
                </div>
              </div>
            </div>
            <div data-test-id="footer_prp">
              <div>
                <div class="bg-premium h-7 flex justify-center items-center rounded-t-lg">
                  <img src="./images/logo.svg?url" class="h-5 w-5" alt="" />
                </div>
                <div
                  class="flex p-4 pb-3 border-2 border-t-0 rounded-b-lg border-dark-500 items-center"
                >
                  <div data-test-id="description_footer_prp" class="flex-1 flex flex-col">
                    <span class="text-button2 text-dark font-semibold"
                      >{{ promoTrial.pkg.label }} Premium</span
                    >
                    <span class="text-button1 text-success font-semibold"
                      >only {{ promoTrial.price }}</span
                    >
                    <span class="text-dark-200 line-through text-caption">{{
                      promoTrial.priceOld
                    }}</span>
                  </div>
                  <FadeTransition>
                    <div v-if="self.timer.isActive" class="flex flex-col items-center">
                      <span class="text-button2 text-error font-semibold">Discount expires in</span>

                      <div
                        data-test-id="timer_footer_prp"
                        class="text-tag text-secondary font-medium p-2 bg-secondary-500 rounded-lg"
                      >
                        {{ self.timer.timeLeftFormatted.mm }}:{{ self.timer.timeLeftFormatted.ss }}
                      </div>
                    </div>
                    <div v-else class="text-center">
                      <div class="text-button2 text-error font-semibold">Last chance</div>
                      <img src="./images/time.png" class="w-5 mt-1" alt="" />
                    </div>
                  </FadeTransition>
                </div>
              </div>

              <SpinnerButton
                v-if="self.cardDefault"
                v-model:is-loading="self.isButtonLoading"
                data-test-id="try_now_footer_prp"
                :loading-duration-ms="10000"
                class="btn h-12 btn-premium w-full mt-3"
                @click="emit('next', promoTrial.pkg)"
              >
                {{ t('tryNow') }}
              </SpinnerButton>
              <PrimaryButton
                v-else
                data-test-id="try_now_footer_prp"
                class="h-12 btn-premium w-full mt-3"
                @click="emit('next', promoTrial.pkg)"
              >
                {{ t('getPremium50') }}
              </PrimaryButton>

              <div v-if="!self.isFromLanding" class="text-overline text-dark-200 text-center mt-3">
                <PaymentMethodPolicyTrial
                  :price-next="promoTrial.nextPackagePrice"
                  :price="promoTrial.price"
                  :btn-text="t('tryNow')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalPortal>
</template>

<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: true, isForceAllowedModal: true };
</script>

<script lang="ts" setup>
import { onUnmounted, PropType, reactive } from 'vue';

import PrimaryButton from '@/components/inputs/button/PrimaryButton.vue';
import SpinnerButton from '@/components/inputs/spinner-button/SpinnerButton.vue';
import ModalPortal from '@/components/modal/ModalPortal.vue';
import { TrialProsperiType } from '@/components/ntf-modal/components/ntf-prosperi-v1/helpers/type/type';
import PaymentMethodPolicyTrial from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-policy-trial/PaymentMethodPolicyTrial.vue';
import FadeTransition from '@/components/transition/FadeTransition.vue';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { MyProfileStateFull } from '@/pages/my-profile/store/my-profile-state';
import { useI18n } from '@/services/i18n';

import { useSelf } from './hooks/use-self/use-self';
import { messages } from './i18n';

const props = defineProps({
  myProfileFull: {
    type: Object as PropType<MyProfileStateFull>,
    required: true,
  },
  type: {
    type: String as PropType<TrialProsperiType>,
    required: true,
  },
  source: {
    type: String as PropType<PromoShowPurchaseSource>,
    required: true,
  },
});

const emit = defineEmits(['close', 'next']);

const { t } = useI18n({ messages });

const self = reactive(
  useSelf({
    type: props.type,
    source: props.source,
  }),
);

const promoTrial = reactive(self.promoTrial);

self.init();

onUnmounted(() => {
  self.destroy();
});
</script>
