import { I18n } from '@/services/i18n';

type Message = {
  beginYourJourney: string;
  welcome: string;
  continuePerWeek: string;
  meetNewNicePeopleOnline: string;
  next: string;
  free: string;
  rightNowYouAreOn: string;
  freeSubscription: string;
  trial: string;
  premium: string;
  seeBasicProfilesOfOtherSingles: string;
  titleFreeSubscription: string;
  limitedChatting: string;
  limitedLikes: string;
  seeFullProfilesOfOtherSingles: string;
  unlimitedChatting: string;
  unlimitedLikes: string;
  unlimitedFilters: string;
  seeWhoVisitsAndLikesYou: string;
  getPremiumSearchBoost: string;
  continue: string;
};

const messages: I18n<Message> = {
  en: {
    beginYourJourney: 'Begin your journey at MeetMyAge!',
    welcome: 'Welcome, {name}!',
    continuePerWeek: 'Try 7-days trial for {price}',
    meetNewNicePeopleOnline:
      'Meet new nice people online, make friends and spend quality time together. This is what you need to know right now about our platform...',
    next: 'Next',
    free: 'Free',
    rightNowYouAreOn: 'Right now you are on',
    freeSubscription: 'free subscription',
    trial: 'Trial',
    premium: 'With Premium',
    seeBasicProfilesOfOtherSingles: 'See basic profiles of other singles',
    titleFreeSubscription: 'Free subscription',
    limitedChatting: 'Limited chatting',
    limitedLikes: 'Limited likes',
    seeFullProfilesOfOtherSingles: 'See full profiles of other singles',
    unlimitedChatting: 'Unlimited chatting',
    unlimitedLikes: 'Unlimited likes',
    unlimitedFilters: 'Unlimited filters',
    seeWhoVisitsAndLikesYou: 'See who visits and likes you',
    getPremiumSearchBoost: 'Get Premium search boost',
    continue: 'Continue',
  },
};

export { messages };
