import { I18n } from '@/services/i18n';

type Message = {
  popular: string;
  save: string;
  best: string;
  tax: string;
};

const messages: I18n<Message> = {
  en: {
    popular: 'Most popular',
    save: 'Save',
    best: 'Best deal',
    tax: 'tax',
  },
};

export { messages };
