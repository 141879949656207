<template>
  <ul class="flex gap-3">
    <li
      v-for="n in 5"
      :key="n"
      class="w-7 h-7 cursor-pointer"
      @click="setRatingModel(n)"
      @mouseover="setRatingDisplay(n)"
      @mouseleave="setRatingDisplay(ratingModel)"
    >
      <SvgIcon v-if="ratingDisplay >= n" name="star_filled" :width="28" :height="28" />
      <SvgIcon v-else name="star" :width="28" :height="28" />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';

import SvgIcon from '@/components/inputs/icon/svg-icon/SvgIcon.vue';
import { useModel } from '@/hooks/use-model/use-model';

const props = defineProps({
  rating: {
    type: Number,
    required: true,
    default: 0,
  },
});

const emit = defineEmits(['update:rating', 'rated']);

const ratingModel = useModel<number>(props, 'rating', emit);

const ratingDisplay = ref(ratingModel.value);

watch(ratingModel, () => {
  emit('rated');
});

function setRatingModel(rating: number) {
  ratingModel.value = rating;
}

function setRatingDisplay(rating: number) {
  ratingDisplay.value = rating;
}
</script>
