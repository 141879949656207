import { computed, reactive, ref } from 'vue';

import { useNtfTimer } from '@/components/ntf-modal/hooks/use-ntf-timer/use-ntf-timer';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { getFormattedCurrency } from '@/helpers/number';
import { trackPaymentPromo } from '@/helpers/track/track-payment/track-payment';
import { getNextPackagePrice, getPackagePrice } from '@/pages/payment/helpers/package/package';
import { PaymentStatePackagePremium } from '@/pages/payment/store/payment-state';
import { getTextPageModal } from '@/pages/text-page/helpers/text-page/text-page';
import { dispatch } from '@/store/store-helper';

type UseSelfArg = {
  source: PromoShowPurchaseSource;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const packageList = ref<PaymentStatePackagePremium[]>([]);
  const packageSelected = ref<PaymentStatePackagePremium>();

  // Hook ----------------------------------------------------------------------
  const timer = useNtfTimer();
  const textPageModal = getTextPageModal();

  // Computed ------------------------------------------------------------------
  const packageSelectedPriceFormatted = computed(() => {
    const packagePrice = getPackagePrice(packageSelected.value!);
    return getFormattedCurrency(packagePrice?.totalAmount ?? 0, packagePrice?.currency ?? 'USD');
  });

  const packageSelectedPriceNextFormatted = computed(() => {
    const packageNextPrice = getNextPackagePrice(packageSelected.value!);
    return getFormattedCurrency(
      packageNextPrice?.totalAmount ?? 0,
      packageNextPrice?.currency ?? 'USD',
    );
  });

  // Method --------------------------------------------------------------------
  async function fetchPackageList() {
    const response = await dispatch('actionCommonGetAvailablePromos');
    packageList.value = response.packages;
  }

  async function init() {
    await fetchPackageList();
    timer.init();

    if (packageList.value.length > 0) {
      packageSelected.value = packageList.value[0];
    }

    trackPaymentPromo({
      from: arg.source,
      popup: 'premium-ntf-v2',
      type: 'premium',
    });
  }

  function destroy() {
    timer.destroy();
  }

  return reactive({
    packageList,
    packageSelected,
    timer,
    textPageModal,
    packageSelectedPriceFormatted,
    packageSelectedPriceNextFormatted,
    init,
    destroy,
  });
}
