import dayjs from 'dayjs';
import { computed } from 'vue';

import { getCache, setCache } from '@/helpers/persistent-cache';
import { useTimer } from '@/hooks/use-timer/use-timer';

type TimeLeftFormatted = {
  mm: string;
  ss: string;
};

const TIMER_DURATION_SECONDS = 600;
const TIMER_CACHE_KEY = 'ntf';
const TIMER_DURATION_RESTART_SECONDS = -1200;

export function useNtfTimer() {
  // Ref -----------------------------------------------------------------------

  // Hook ----------------------------------------------------------------------
  const timer = useTimer('ntf');
  let tickId: number = 0;

  // Computed ------------------------------------------------------------------
  const timeLeftFormatted = computed<TimeLeftFormatted>(() => {
    const duration = dayjs.duration(timer.timerSecondsLeft.value, 'seconds');
    return {
      mm: duration.format('mm'),
      ss: duration.format('ss'),
    };
  });

  // Computed ------------------------------------------------------------------
  const isActive = computed(() => {
    return timer.timerSecondsLeft.value > 0;
  });

  // Method --------------------------------------------------------------------
  function resetTimerCache() {
    const timerEnds = dayjs().add(TIMER_DURATION_SECONDS, 'seconds').unix();
    setCache(TIMER_CACHE_KEY, timerEnds.toString());

    return timerEnds;
  }

  function getTimerSecondsLeft() {
    let timerEnds = Number(getCache(TIMER_CACHE_KEY));
    if (timerEnds === 0) {
      timerEnds = resetTimerCache();
    }

    return timerEnds - dayjs().unix();
  }

  function tick() {
    const secondsLeft = getTimerSecondsLeft();
    if (secondsLeft < TIMER_DURATION_RESTART_SECONDS) {
      console.log('timer expired');
      resetTimerCache();
      timer.startTimer(TIMER_DURATION_SECONDS, true);
    }
  }

  function init() {
    timer.startTimer(getTimerSecondsLeft());
    tickId = window.setInterval(tick, 1000);
  }

  function destroy() {
    if (tickId) {
      clearInterval(tickId);
      tickId = 0;
    }
  }

  return {
    timeLeftFormatted,
    isActive,
    init,
    destroy,
  };
}
