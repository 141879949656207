import { showNotification } from '@/components/notification/helpers/notification-shower/notification-shower';
import { clearDelayedTrialPromoModal } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/subscription-promo-helper';
import { historyPaths, router } from '@/router';
import {
  addHistoryItem,
  goNextIfDefaultPath,
  goNextIfUserIsNotGuest,
  resolveWebPushLogic,
  resolveSignUpNavigation,
  addDocumentTitle,
  addRouteMeta,
  resolveRestrictedOrDeactivated,
} from '@/router/helpers/router-guards/helpers/router-guards-helper/router-guards-helper';

export function initRouterBeforeEach(): void {
  router.beforeEach(async (to, from, next) => {
    try {
      addHistoryItem(to);
      addDocumentTitle(to);
      addRouteMeta(to);
      resolveWebPushLogic(to);
      resolveSignUpNavigation(to, next);

      if (to.path === '/') {
        await goNextIfDefaultPath(next);
      } else if (!to.meta.isGuest) {
        // Save all but root pages to server
        await goNextIfUserIsNotGuest(to);
      } else {
        clearDelayedTrialPromoModal();
      }

      resolveRestrictedOrDeactivated(to, from, next);
    } catch (e) {
      historyPaths.pop();
    }
  });
}

export function initRouterOnError(): void {
  router.onError((error) => {
    if (error.name === 'ChunkLoadError') {
      showNotification(
        {
          type: 'new-version',
        },
        {
          duration: 60000,
        },
      );
    }
  });
}
