import { I18n } from '@/services/i18n';

type Message = {
  continue: string;
  save: string;
  saveUp: string;
  limitedOffer: string;
  takeIt: string;
  takeItLater: string;
  expireOffer: string;
  week: string;
  free: string;
  hello: string;
  youHaveUnlocked: string;
};

const messages: I18n<Message> = {
  en: {
    continue: 'Continue',
    save: 'Save {percent}%',
    saveUp: 'Save up to {percent}%',
    limitedOffer: 'Special Bonus Unlocked',
    takeIt: 'TAKE IT',
    takeItLater: 'No thanks, I’d rather pay full price',
    expireOffer:
      'Although your trial offer has expired we offer you a personal discount -{discountPercent}%',
    week: 'week',
    free: 'Free',
    hello: 'Hello',
    youHaveUnlocked: 'You have unlocked a special offer!',
  },
};

export { messages };
