import { TrialProsperiType } from '@/components/ntf-modal/components/ntf-prosperi-v1/helpers/type/type';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';

type GetNtfProsperiTypeArg = {
  source: PromoShowPurchaseSource;
};
export function getNtfProsperiType(arg: GetNtfProsperiTypeArg): TrialProsperiType {
  if (arg.source === 'chat-send-message') {
    return 'message';
  } else if (
    arg.source === 'search-insufficient-likes' ||
    arg.source === 'profile-insufficient-likes'
  ) {
    return 'like';
  } else {
    return 'other';
  }
}
