import { I18n } from '@/services/i18n';

type Message = {
  slider: {
    gift: {
      header: string;
      text: string;
    };
    boost: {
      header: string;
      text: string;
    };
    power: {
      header: string;
      text: string;
    };
    chatRequest: {
      header: string;
      text: string;
    };
    astrology: {
      header: string;
      text: string;
    };
    sexual: {
      header: string;
      text: string;
    };
  };
};

const messages: I18n<Message> = {
  en: {
    slider: {
      gift: {
        header: 'Virtual gifts',
        text: 'Use credits for virtual gifts',
      },
      boost: {
        header: 'Move to the top',
        text: 'Move up to the top and get seen by more people',
      },
      power: {
        header: 'Chat Request',
        text: 'Use the force of Chat Request feature to start chatting without mutual like',
      },
      chatRequest: {
        header: 'Chat Request',
        text: 'Use the force of Chat Request to start chatting without mutual like',
      },
      astrology: {
        header: 'Astrology compatibility',
        text: 'Ask the starts whether or not to like a person',
      },
      sexual: {
        header: 'Romantic compatibility',
        text: 'Based on one’s profile info one can check the romantic fit between your possible match',
      },
    },
  },
};

export { messages };
