import { I18n } from '@/services/i18n';

type Message = {
  header: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Subscription has been activated!',
  },
};

export { messages };
