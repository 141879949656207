import { I18n } from '@/services/i18n';

type Message = {
  thereIsHit: string;
  sayHello: string;
  likedEachOther: string;
};

const messages: I18n<Message> = {
  en: {
    thereIsHit: 'There is a hit!',
    sayHello: 'Say “Hello”',
    likedEachOther: 'You and {name} liked each other. Sending a gift is a perfect ice-breaker',
  },
};

export { messages };
