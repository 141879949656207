import { GetCompatibilityStatusResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/compatibility/v1/compatibility_api_pb';

import { ExtractClassFields } from '@/type';

export type CompatibilityState = {
  compatibilityStatuses: {
    [key: string]: ExtractClassFields<GetCompatibilityStatusResponse>;
  };
  sexualCompatibilityStatuses: {
    [key: string]: ExtractClassFields<GetCompatibilityStatusResponse>;
  };
};

export const state: CompatibilityState = {
  compatibilityStatuses: {},
  sexualCompatibilityStatuses: {},
};
