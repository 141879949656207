import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  premium: string;
  premiumGold: string;
  fewerPlans: string;
  morePlans: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Activate Premium',
    premium: 'Premium',
    premiumGold: 'Premium Gold',
    fewerPlans: 'Fewer plans',
    morePlans: 'More plans',
  },
};

export { messages };
