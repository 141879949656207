import dayjs from 'dayjs';

import { assertNever } from '@/helpers/type/type';
import { setSentryLocale } from '@/services/sentry/sentry';

export const enum Locale {
  En = 'en',
}

export type I18n<T> = Record<Locale, T>;
export type I18nMessages = Record<string, unknown>;

// const i18n = createI18n({
//   locale: Locale.En,
//   // legacy: false,
// });

const i18n = {
  global: {
    locale: Locale.En,
  },
};

export function setLocale(locale: Locale) {
  i18n.global.locale = locale;

  if (locale === Locale.En) {
    import('dayjs/locale/en').then(() => {
      dayjs.locale(locale);
    });
  } else {
    assertNever(locale);
  }

  // Set new language for tracing into sentry.
  setSentryLocale(locale);
}

export function useI18n<T extends I18n<I18nMessages>>({ messages }: { messages: T }) {
  return {
    t: (
      key: (T extends I18n<infer X> ? keyof X : never) | string,
      data?: Record<string, string | number>,
    ): string => {
      let message = messages[i18n.global.locale as Locale];

      key.split('.').forEach((key) => (message = message[key] as I18nMessages));

      if (typeof message === 'object') {
        return key;
      }

      if (!message) {
        console.warn('[i18n] translation for `' + key + '` not found');
      }

      let result = message as string;
      if (data) {
        Object.keys(data).forEach((k) => {
          result = result?.replace(new RegExp(`{${k}}`, 'g'), data[k]?.toString());
        });
      }

      return result ?? key;
    },
  };
}

export { i18n };
