import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  gotIt: string;
  credits: string;
  sendSomeone: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'are unused and will expire soon',
    gotIt: 'Got it',
    credits: 'credits',
    sendSomeone:
      'Send someone a gift, boost your profile, check Astrological or Romantic compatibility with someone.',
  },
};

export { messages };
