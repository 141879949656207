import {
  CreditsPackage,
  PremiumPackage,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';

import { usePayment } from '@/components/payment/hooks/use-payment/use-payment';
import { trackPaymentClick } from '@/helpers/track/track-payment/track-payment';
import PaymentMethod from '@/pages/payment/components/payment-method/PaymentMethod.vue';
import { handlePayError } from '@/pages/payment/components/purchase/helpers/purchase-helper/purchase-helper';
import { PaymentStateCard, PaymentStatePackage } from '@/pages/payment/store/payment-state';
import { replaceRoute } from '@/router';
import { hideAllModals, useModal } from '@/services/modal/modal';
import { commit, dispatch, getState } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

export type PayWithCard = {
  pkg: ExtractClassFields<PremiumPackage> | ExtractClassFields<CreditsPackage>;
  cardId: string;
};

export function setLastPurchasedPackage(pkg: PaymentStatePackage): void {
  commit('mutationPaymentSetLastPurchasedPackage', pkg);
}

export async function payWithCard({ cardId, pkg }: PayWithCard) {
  setLastPurchasedPackage(pkg);
  try {
    trackPaymentClick({
      method: 'token',
    });
    await dispatch('actionPaymentPayByCardId', {
      cardId,
      pkg: {
        id: pkg.id,
        offerId: pkg.offer?.id ?? '',
      },
    });
  } catch (e) {
    handlePayError(e);
  }
}

export async function loadCards() {
  await dispatch('actionPaymentListPaymentCards');
  return getState().payment.paymentCards;
}

export function paymentListenResult({
  onSuccess,
  onFail,
}: {
  onSuccess?: () => void;
  onFail?: () => void;
}) {
  usePayment({
    paymentSuccess: () => {
      if (onSuccess) {
        onSuccess();
      } else {
        replaceRoute('paymentSuccess');
        hideAllModals();
      }
    },
    paymentFail: () => {
      if (onFail) {
        onFail();
      } else {
        replaceRoute('paymentFail');
        hideAllModals();
      }
    },
  });
}

type PaymentParams = {
  pkg: PaymentStatePackage;
  onClose?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
};

export async function paymentPurchase({
  pkg,
  onClose = () => ({}),
  onSuccess,
  onFail,
}: PaymentParams) {
  const cards = await loadCards();
  const defaultPaymentCard = cards[0];

  if (defaultPaymentCard) {
    paymentListenResult({
      onSuccess,
      onFail,
    });
    await payWithCard({
      cardId: defaultPaymentCard.id,
      pkg,
    });
  } else {
    await paymentSelect({ pkg, onClose, onSuccess, onFail });
  }
}

export async function paymentSelect({
  pkg,
  onClose: close = () => ({}),
  onSuccess,
  onFail,
  forceAddNewMethod = false,
}: {
  pkg: PaymentStatePackage;
  onClose?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
  forceAddNewMethod?: boolean;
}) {
  setLastPurchasedPackage(pkg);
  let cards: PaymentStateCard[] = [];
  if (!forceAddNewMethod) {
    cards = await loadCards();
  }
  paymentListenResult({
    onSuccess,
    onFail,
  });

  const { showModal } = useModal(() => ({
    component: PaymentMethod,
    props: {
      pkg,
      cards,
    },
    on: {
      close,
    },
  }));
  showModal();
}
