import Swiper from 'swiper';
import { computed, reactive } from 'vue';

import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { getUniqNumber } from '@/helpers/number';

type UseSelfArg = {
  feature?: SubscriptionFeature;
};

export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const id = getUniqNumber();

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------
  const slideInitial = computed(() => {
    switch (arg.feature) {
      case SubscriptionFeature.ADVANCED_INFORMATION:
        return 0;
      case SubscriptionFeature.UNLIMITED_LIKES:
        return 1;
      case SubscriptionFeature.ADVANCED_SEARCH:
        return 2;
      case SubscriptionFeature.UNLIMITED_MESSAGES:
        return 3;
      default:
        return 3;
    }
  });

  // Method --------------------------------------------------------------------
  function initSwiper() {
    new Swiper(`.swiper-gallery-container${id}`, {
      loop: false,
      initialSlide: slideInitial.value,
      pagination: {
        el: `.swiper-gallery-pagination${id}`,
        type: 'bullets',
        clickable: true,
        bulletClass: 'bullet',
        bulletActiveClass: 'active',
        renderBullet(index, className) {
          return `<div class="h-1.5 w-1.5 bg-white opacity-40 rounded-full ${className}"></div>`;
        },
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    });
  }

  async function init() {
    initSwiper();
  }

  return reactive({
    id,
    init,
  });
}
