import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { I18n } from '@/services/i18n';

type Message = {
  slider: Record<
    SubscriptionFeature,
    {
      header: string;
      content: string;
    }
  >;
};

const messages: I18n<Message> = {
  en: {
    slider: {
      unlimitedLikes: {
        header: 'Unlimited likes',
        content: 'You have infinite likes',
      },
      advancedSearch: {
        header: 'Advanced Search',
        content: 'Get all the information you need',
      },
      unblockUsers: {
        header: 'Unblock users',
        content: 'You can block and unblock users when you feel the need',
      },
      unlimitedMessages: {
        header: 'Unlimited chatting',
        content: 'No limits on chatting and messaging',
      },
      deleteOwnMessages: {
        header: 'Delete own messages',
        content: 'You can delete a message if it was sent less than 24 hours ago',
      },
      advancedInformation: {
        header: 'Advanced information',
        content: "You can see every users' advanced information in their profiles",
      },
      activity: {
        header: 'Activities',
        content: 'You can see all incoming & outgoing activities',
      },
      ads: {
        header: 'No Ads',
        content: 'Tired of ads? Upgrade and don’t get bothered by advertising',
      },
    },
  },
};

export { messages };
