import { createPromiseClient } from '@connectrpc/connect';
import { ActivityAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/activity/v1/activity_api_connect';
import {
  GetActivityStatusRequest,
  GetActivityStatusResponse,
  ListActivitiesRequest,
  ListActivitiesResponse,
  MakeActivitiesReadRequest,
  MakeActivitiesReadResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/activity/v1/activity_api_pb';

import { getGrpcWebTransport } from '@/services/api/grpc-api';
import { ExtractClassFields } from '@/type';

const client = createPromiseClient(ActivityAPI, getGrpcWebTransport());

export function makeActivitiesRead(): Promise<MakeActivitiesReadResponse> {
  const request = new MakeActivitiesReadRequest();

  return client.makeActivitiesRead(request);
}

export function getActivityStatus(): Promise<GetActivityStatusResponse> {
  const request = new GetActivityStatusRequest();

  return client.getActivityStatus(request);
}

export function listActivities({
  direction,
  limit,
  searchFrom,
}: ExtractClassFields<ListActivitiesRequest>): Promise<ListActivitiesResponse> {
  const request = new ListActivitiesRequest({
    direction,
    limit,
    searchFrom,
  });

  return client.listActivities(request);
}
