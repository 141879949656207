import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  paymentDetails: string;
  terms: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Payment methods',
    paymentDetails: 'Payment details',
    terms: 'Please, accept the terms first.',
  },
};

export { messages };
