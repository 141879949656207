import {
  showNotificationNewMessage,
  showNotificationNewMessageThrottled,
} from '@/components/notification/helpers/notification-websocket/notification-websocket';
import { showSubscriptionPromoModal } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/subscription-promo-helper';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { getMergeNotificationsSplitIsV2 } from '@/helpers/split/split';
import { getMessageFrom, getPreviewMessageFrom } from '@/pages/chat/store/helpers/chat-helper';
import { NotificationHandler } from '@/services/websocket';
import { commit, dispatch, getStore } from '@/store/store-helper';

export const chatNotificationHandler: NotificationHandler = (notification) => {
  switch (notification.notification.case) {
    case 'dialogMessageV2': {
      console.log('DIALOG_MESSAGE_V2');
      const dialogNotification = notification.notification.value;

      if (dialogNotification) {
        const rawMessage = dialogNotification.message;
        const chatId = dialogNotification.dialogId;

        if (rawMessage) {
          const message = getMessageFrom(rawMessage, '');
          const previewMessage = getPreviewMessageFrom(rawMessage);

          commit('mutationChatAddMessage', {
            chatId,
            message,
          });

          commit('mutationChatAddLastMessage', { chatId, message: previewMessage });
          getMergeNotificationsSplitIsV2().then((isOn) => {
            if (isOn) {
              showNotificationNewMessage(dialogNotification.dialogId, rawMessage);
            } else {
              showNotificationNewMessageThrottled(dialogNotification.dialogId, rawMessage);
            }
          });
        }
      }

      break;
    }

    case 'dialogMessageDeleted': {
      const dialogMessageDeleted = notification.notification.value;

      if (dialogMessageDeleted) {
        const dialogId = dialogMessageDeleted.dialogId;
        const messageId = dialogMessageDeleted.messageId;

        if (dialogId && messageId) {
          commit('mutationChatDeleteMessages', {
            chatId: dialogId,
            messageIds: [messageId],
          });
        }
      }

      break;
    }

    case 'suspiciousContentReported': {
      const suspiciousContentReported = notification.notification.value;

      if (suspiciousContentReported) {
        const dialogId = suspiciousContentReported.dialogId;
        const messageId = suspiciousContentReported.messageId;

        if (dialogId && messageId) {
          commit('mutationChatSuspiciousContentReported', {
            dialogId,
            messageId,
          });
        }
      }

      break;
    }

    case 'dialogMessageRestricted': {
      const restricted = notification.notification.value;

      if (restricted) {
        const dialogId = restricted.dialogId;
        const messageId = restricted.messageId;
        const message = getStore().state.chat.drafts[dialogId];
        let isTimeOutCalled = false;

        // need this logic for managing async behaviour GDP-2654
        const deleteMessage = () => {
          const lastMessage = getStore().state.chat.messages[dialogId]?.find(
            (item) => item.id === messageId,
          );

          if (dialogId && messageId && lastMessage) {
            commit('mutationChatDeleteMessages', {
              chatId: dialogId,
              messageIds: [messageId],
            });
            commit('mutationChatAddActiveDraftsMessage', { dialogId, message });
          } else if (!isTimeOutCalled) {
            setTimeout(() => deleteMessage(), 2000);
            isTimeOutCalled = true;
          }
        };

        deleteMessage();

        const dialogUser = getStore().state.chat.dialogUsers[dialogId];

        if (dialogUser) {
          dispatch('actionProfileGetConnectionState', dialogUser.id);
          showSubscriptionPromoModal(SubscriptionFeature.UNLIMITED_MESSAGES, 'chat-send-message', {
            userReceiver: {
              avatarId: dialogUser.avatarId,
            },
          });
        }
      }

      break;
    }

    default:
      break;
  }
};
