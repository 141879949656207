import { reactive, ref } from 'vue';

import { myProfileApi } from '@/services/api/my-profile/my-profile-api';

type Step = 'rate' | 'comment' | 'thanks';

type UseSelfArg = {
  //
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const step = ref<Step>('rate');
  const rating = ref(0);
  const comment = ref('');

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------

  function handleRated(): void {
    if (step.value === 'rate') {
      setStep('comment');
    }
  }

  async function submitReview() {
    await myProfileApi.submitTrustPilotReview({
      comment: comment.value,
      score: rating.value,
    });

    setStep('thanks');
  }

  function setStep(stepNew: Step) {
    step.value = stepNew;
  }

  function init() {
    //
  }

  return reactive({
    step,
    rating,
    comment,
    handleRated,
    submitReview,
    setStep,
    init,
  });
}
