import { VNodeRef } from 'vue';
import { ref, watch } from 'vue';

import { loadScript } from '@/helpers/script/script';
import { trackPaymentClick } from '@/helpers/track/track-payment/track-payment';
import { PaymentStatePackage } from '@/pages/payment/store/payment-state';
import { initiatePayPalPayment } from '@/services/api/payment/payment-api';

type UseSelfArg = {
  pkg: PaymentStatePackage;
};

export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const isLoading = ref(true);
  const buttonEl = ref<HTMLElement | null>(null);

  // Hook ----------------------------------------------------------------------
  watch(buttonEl, (el) => {
    if (el) {
      addPayPalEventListeners(el);
    }
  });

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  const setButtonEl: VNodeRef = (el) => {
    buttonEl.value = el as HTMLElement;
  };

  function addPayPalEventListeners(buttonElArg: HTMLElement) {
    buttonElArg.addEventListener(
      'order-started-processing',
      function (e) {
        isLoading.value = true;
        console.log('order-started-processing', e);
        trackPaymentClick({
          method: 'paypal',
        });
      },
      false,
    );
    buttonElArg.addEventListener(
      'order-started-approved',
      function (e) {
        console.log('order-approved', e);
      },
      false,
    );
    buttonElArg.addEventListener(
      'order-processed',
      function (e) {
        isLoading.value = false;
        console.log('order-processed', e);
      },
      false,
    );
    buttonElArg.addEventListener(
      'order-already-processed',
      function (e) {
        console.log('order-already-processed', e);
      },
      false,
    );
    buttonElArg.addEventListener(
      'button-ready',
      function (e) {
        console.log('button-ready', e);
      },
      false,
    );
    buttonElArg.addEventListener(
      'button-error',
      function (e) {
        console.log('button-error', e);
      },
      false,
    );
  }

  function loadPayPalScript(scriptUrl: string) {
    loadScript(scriptUrl, {
      'data-label': 'paypal',
      'data-color': 'gold',
      'data-shape': 'rect',
    }).then(() => {
      isLoading.value = false;
    });
  }

  function init() {
    initiatePayPalPayment({
      id: arg.pkg.id,
      offerId: arg.pkg.offer?.id ?? '',
    }).then((response) => {
      loadPayPalScript(response.scriptUrl);
    });
  }

  return {
    isLoading,
    setButtonEl,
    init,
  };
}
