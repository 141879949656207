import { Possibility } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/possibilities/v1/possibilities_pb';
import { ActionContext } from 'vuex';

import { PossibilityPackage } from '@/pages/my-profile/components/my-profile-view/components/my-profile-boosters-grid/components/profile-card/helpers/package-id-helper/package-id-helper';
import { listPossibilityState, getPackages } from '@/services/api/possibilities/possibilities-api';
import { PossibilityState } from '@/store/modules/possibility/possibility-state';
import { commit, ActionFunction } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

declare module '@/store/store-helper' {
  interface Action {
    actionPossibilityListPossibilities: ActionFunction<never, ExtractClassFields<Possibility>[]>;
    actionPossibilityBatchGetPackages: ActionFunction<PossibilityPackage[], never>;
  }
}

export async function actionPossibilityListPossibilities(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PossibilityState, unknown>,
): Promise<ExtractClassFields<Possibility>[]> {
  const response = await listPossibilityState();
  const possibilities = response.possibilities;

  commit('mutationPossibilitySetPossibilitiesList', possibilities);

  return possibilities;
}

export async function actionPossibilityBatchGetPackages(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PossibilityState, unknown>,
  list: PossibilityPackage[],
): Promise<void> {
  const response = await getPackages(list);
  const pkgList = response.packages;

  commit('mutationPossibilitySetPackagesList', pkgList);
}
