import { getGrowthBookFeatureValue } from '@/services/growth-book/growth-book';

export async function getMergeNotificationsSplitIsV2() {
  // return Promise.resolve(true);
  const splitVariant = await getMergeNotificationsSplitVariant();

  return splitVariant === 'v2';
}

export async function getMergeNotificationsSplitVariant() {
  return getGrowthBookFeatureValue('merge-notif2', 'def');
}
