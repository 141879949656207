import {
  ProfileBodyType,
  ProfileChildren,
  ProfileDrinking,
  ProfileEducation,
  ProfileEthnicity,
  ProfileEyeColor,
  ProfileGender,
  ProfileHairColor,
  ProfileLocation,
  ProfileLookingFor,
  ProfileMaritalStatus,
  ProfileReligion,
  ProfileSexualOrientation,
  ProfileSmoking,
  ProfileSphereOfWork,
  ProfileSport,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';
import { SearchProfile } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/search_pb';

import {
  SETTINGS_PROFILE_DEFAULT_AGE_FROM,
  SETTINGS_PROFILE_DEFAULT_AGE_TO,
  SETTINGS_PROFILE_DEFAULT_CITY_NAME,
  SETTINGS_PROFILE_DEFAULT_COUNTRY_NAME,
  SETTINGS_PROFILE_DEFAULT_HEIGHT_FROM,
  SETTINGS_PROFILE_DEFAULT_HEIGHT_TO,
  SETTINGS_PROFILE_DEFAULT_LOCATION_LATITUDE,
  SETTINGS_PROFILE_DEFAULT_LOCATION_LONGITUDE,
} from '@/settings';
import { ExtractClassFields } from '@/type';

export type SearchStateResultProfile = ExtractClassFields<SearchProfile>;

export type SearchStateFilter = {
  isEmptySearch: boolean;
  gender: ProfileGender;
  ageFrom: number;
  ageTo: number;
  location: ExtractClassFields<ProfileLocation>;
  isNew: boolean;
  isOnline: boolean;
  smoking: ProfileSmoking[];
  drinking: ProfileDrinking[];
  maritalStatus: ProfileMaritalStatus[];
  children: ProfileChildren;
  sexualOrientation: ProfileSexualOrientation[];
  religion: ProfileReligion[];
  ethnicity: ProfileEthnicity[];
  bodyType: ProfileBodyType[];
  hairColor: ProfileHairColor[];
  eyeColor: ProfileEyeColor[];
  sport: ProfileSport[];
  lookingFor: ProfileLookingFor[];
  heightFrom: number;
  heightTo: number;
  education: ProfileEducation[];
  sphereOfWork: ProfileSphereOfWork[];
  interests: string[];
  hasPhotos?: boolean;
};

export type SearchState = {
  filter: SearchStateFilter;
  profiles: SearchStateResultProfile[];
};

export function getSearchStateDefaultFilter(): SearchStateFilter {
  return {
    isEmptySearch: true,
    gender: ProfileGender.INVALID,
    ageFrom: SETTINGS_PROFILE_DEFAULT_AGE_FROM,
    ageTo: SETTINGS_PROFILE_DEFAULT_AGE_TO,
    location: {
      latitude: SETTINGS_PROFILE_DEFAULT_LOCATION_LATITUDE,
      longitude: SETTINGS_PROFILE_DEFAULT_LOCATION_LONGITUDE,
      city: SETTINGS_PROFILE_DEFAULT_CITY_NAME,
      country: SETTINGS_PROFILE_DEFAULT_COUNTRY_NAME,
      state: '',
    },
    isNew: false,
    isOnline: false,
    smoking: [],
    drinking: [],
    maritalStatus: [],
    children: ProfileChildren.INVALID,
    sexualOrientation: [],
    religion: [],
    ethnicity: [],
    bodyType: [],
    hairColor: [],
    eyeColor: [],
    sport: [],
    lookingFor: [],
    heightFrom: SETTINGS_PROFILE_DEFAULT_HEIGHT_FROM,
    heightTo: SETTINGS_PROFILE_DEFAULT_HEIGHT_TO,
    education: [],
    sphereOfWork: [],
    interests: [],
  };
}

export const state: SearchState = {
  filter: getSearchStateDefaultFilter(),
  profiles: [],
};
