<template>
  <div class="h-full w-full max-w-screen-xs mx-auto relative">
    <template v-if="self.splitNtf.variant === 'v1'">
      <NtfWelcomeV1
        v-if="self.isWelcomePopupAvailable"
        :card-default="self.cardDefault"
        :source="source"
        @next="self.purchasePkg"
        @close="emit('close')"
      />
      <NtfProsperiV1
        v-else-if="self.isProsperiSource && self.profileFull"
        :type="getNtfProsperiType({ source })"
        :my-profile-full="self.profileFull"
        :source="source"
        @next="self.purchasePkg"
        @close="emit('close')"
      />
      <NtfTrialV1
        v-else
        :card-default="self.cardDefault"
        :source="source"
        @next="self.purchasePkg"
        @close="emit('close')"
      />
    </template>
    <template v-else>
      <NtfWelcomeV2
        v-if="self.isWelcomePopupAvailable"
        :card-default="self.cardDefault"
        :source="source"
        @next="self.setIsWelcomePopupAvailable(false)"
        @close="emit('close')"
      />
      <NtfPremiumV2
        v-else-if="self.profileFull"
        :source="source"
        :feature="feature"
        :my-profile-full="self.profileFull"
        @close="emit('close')"
        @next="self.purchasePkg"
        @select="self.selectPkg"
      />
    </template>
  </div>
</template>

<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: true, isForceAllowedModal: true };
</script>

<script lang="ts" setup>
import { PropType } from 'vue';

import NtfPremiumV2 from '@/components/ntf-modal/components/ntf-premium-v2/NtfPremiumV2.vue';
import { getNtfProsperiType } from '@/components/ntf-modal/components/ntf-prosperi-v1/helpers/ntf-prosperi/ntf-prosperi';
import NtfProsperiV1 from '@/components/ntf-modal/components/ntf-prosperi-v1/NtfProsperiV1.vue';
import NtfTrialV1 from '@/components/ntf-modal/components/ntf-trial-v1/NtfTrialV1.vue';
import NtfWelcomeV1 from '@/components/ntf-modal/components/ntf-welcome-v1/NtfWelcomeV1.vue';
import NtfWelcomeV2 from '@/components/ntf-modal/components/ntf-welcome-v2/NtfWelcomeV2.vue';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';

import { useSelf } from './hooks/use-self/use-self';

const emit = defineEmits(['close']);

const props = defineProps({
  feature: {
    type: String as PropType<SubscriptionFeature>,
    default: undefined,
  },
  source: {
    type: String as PropType<PromoShowPurchaseSource>,
    required: true,
  },
});

const self = useSelf({
  source: props.source,
});

self.init();
</script>
