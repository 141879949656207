import { I18n } from '@/services/i18n';

type Message = {
  automaticallyTopUpMyCredits: string;
  automaticallyTopUpMyCreditsV2: string;
};

const messages: I18n<Message> = {
  en: {
    automaticallyTopUpMyCreditsV2:
      'Automatically top up my credits when the balance falls below 200 credits.',
    automaticallyTopUpMyCredits:
      "Yes, I'd like to refill my credits balance automatically if there is not enough for the purchase.",
  },
};

export { messages };
