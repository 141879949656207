import { computed } from 'vue';

import ModalSpinner from '@/components/modal/ModalSpinner.vue';
import { initSolidForm } from '@/components/payment/components/payment-method/components/solid/solid-payment-method/helpers/self/self';
import { getUniqNumber } from '@/helpers/number';
import { trackPaymentFlow } from '@/helpers/track/track-payment/track-payment';
import { PaymentStatePackage } from '@/pages/payment/store/payment-state';
import { useModal } from '@/services/modal/modal';
import { getState } from '@/store/store-helper';

type UseSelfArg = {
  pkg: PaymentStatePackage;
  isCardEnabled: boolean;
  isGooglePayEnabled: boolean;
  isApplePayEnabled: boolean;
  isAuthorizeCardToken: boolean;
};

export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  let destroySolidForm: () => void;
  const solidCardElId = 'mma-payment-form-container' + getUniqNumber();
  const solidGooglePayElId = 'mma-google-pay-container' + getUniqNumber();
  const solidApplePayElId = 'mma-apple-pay-container' + getUniqNumber();

  // Hook ----------------------------------------------------------------------
  const spinnerModal = useModal(() => ({
    component: ModalSpinner,
  }));

  // Computed ------------------------------------------------------------------
  const isGooglePayAvailable = computed<boolean>(() => {
    return getState().payment.paymentMethods.googlePay.isAvailable;
  });

  const isApplePayAvailable = computed<boolean>(() => {
    return getState().payment.paymentMethods.applePay.isAvailable;
  });

  // Method --------------------------------------------------------------------
  function destroy() {
    destroySolidForm();
  }

  async function init() {
    spinnerModal.showModal();

    destroySolidForm = (
      await initSolidForm({
        pkg: arg.pkg,
        isCardEnabled: arg.isCardEnabled,
        isGooglePayEnabled: arg.isGooglePayEnabled && isGooglePayAvailable.value,
        isApplePayEnabled: arg.isApplePayEnabled && isApplePayAvailable.value,
        isAuthorizeCardToken: arg.isAuthorizeCardToken,
        btnSubmitText: 'Submit',
        containerId: solidCardElId,
        googlePayContainerId: solidGooglePayElId,
        applePayContainerId: solidApplePayElId,

        handleFormMounted() {
          spinnerModal.hideModal();
        },
      })
    ).destroyForm;

    trackPaymentFlow({
      step: 'solid-form',
    });
  }

  return {
    solidCardElId,
    solidGooglePayElId,
    solidApplePayElId,
    isGooglePayAvailable,
    isApplePayAvailable,
    destroy,
    init,
  };
}
