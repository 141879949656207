import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  description: string;
  actionButtonText: string;
};

const messages: I18n<Message> = {
  en: {
    header: "You're double checked by",
    description: '{name} checked their astrological & romantic compatibility with you',
    actionButtonText: 'Visit profile',
  },
};

export { messages };
