// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce(func: (...args: any[]) => void, waitMs: number) {
  let timeout: number | undefined;
  return (...args: unknown[]) => {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      timeout = undefined;
      func(...args);
    }, waitMs);
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function throttle(func: (...args: any[]) => void, timeFrame: number) {
  let lastTime = 0;
  return (...args: unknown[]) => {
    const now = new Date().getTime();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
}

export function arraysEqual<T>(array1: T[], array2: T[]): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
}

export function unionBy(...arrays: unknown[]) {
  const iteratee = arrays.pop() as (o: unknown) => unknown;

  if (Array.isArray(iteratee)) {
    return []; // return empty if iteratee is missing
  }

  return [...arrays]
    .flat()
    .filter(
      ((set) => (o: unknown) => (set.has(iteratee(o)) ? false : set.add(iteratee(o))))(new Set()),
    );
}
