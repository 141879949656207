import { Duration } from '@bufbuild/protobuf';
import { createPromiseClient } from '@connectrpc/connect';
import {
  EmailCredential,
  ExternalCredential,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/auth_pb';
import { MyProfileAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/my_profile_api_connect';
import {
  CheckEmailRequest,
  CheckEmailResponse,
  ConfirmNewPasswordRequest,
  ConfirmNewPasswordResponse,
  DeactivateAccountRequest,
  DeactivateAccountResponse,
  DeleteAccountRequest,
  DeleteAccountResponse,
  DeletePhotoRequest,
  DeletePhotoResponse,
  GetAccountRequest,
  GetAccountResponse,
  GetExternalUserInfoRequest,
  GetExternalUserInfoResponse,
  GetFacebookTrackingParamsRequest,
  GetFacebookTrackingParamsResponse,
  GetFullProfileRequest,
  GetFullProfileResponse,
  GetIsSafeRequest,
  GetIsSafeResponse,
  GetOneTimeHashRequest,
  GetOneTimeHashResponse,
  GetShortProfileRequest,
  GetShortProfileResponse,
  GetStateRequest,
  GetStateResponse,
  ListProfileTagsRequest,
  ListProfileTagsResponse,
  ReactivateAccountRequest,
  ReactivateAccountResponse,
  SendPasswordRestorationRequest,
  SendPasswordRestorationResponse,
  SignInRequest,
  SignInResponse,
  SignOutRequest,
  SignOutResponse,
  SignUpRequest,
  SignUpResponse,
  StoreClientEventRequest,
  StoreClientEventResponse,
  SubmitOffboardingSurveyRequest,
  SubmitOffboardingSurveyResponse,
  SubmitTrustPilotReviewRequest,
  SubmitTrustPilotReviewResponse,
  UpdateAvatarRequest,
  UpdateAvatarResponse,
  UpdateProfileLocationRequest,
  UpdateProfileLocationResponse,
  UpdateProfileRequest,
  UpdateProfileResponse,
  UploadPhotoRequest,
  UploadPhotoResponse,
  ValidateEmailRequest,
  ValidateEmailResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/my_profile_api_pb';
import {
  ProfileBirthday,
  ProfileGender,
  ProfileLocation,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';
import { ProfileField } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/update_pb';

import { getGrpcWebTransport } from '@/services/api/grpc-api';
import { GrpcMetadata } from '@/services/api/interceptor/interceptor-auth/interceptor-auth';
import { ExternalUserInfoProvider } from '@/services/api/my-profile/helpers/type/type';
import { ExtractClassFields } from '@/type';

const client = createPromiseClient(MyProfileAPI, getGrpcWebTransport());

export type GetExternalUserInfoArgument = {
  provider: ExternalUserInfoProvider;
  sessionId: string;
};

export type SubmitOffboardingSurveyReason =
  | 'technical_issues'
  | 'cant_find_anyone'
  | 'too_expensive'
  | 'notifications'
  | 'other'
  | '';

type SignupArgumentExternalCredentials = {
  type: 'external';
  provider: ExternalUserInfoProvider;
  sessionId: string;
};

type SignupArgumentEmailCredentials = {
  type: 'email';
  email: string;
  password: string;
};

export type SignupArgument = {
  userNewId?: string;
  email: string;
  gender: ProfileGender;
  matchGender: ProfileGender;
  name: string;
  birthdate: ExtractClassFields<ProfileBirthday>;
  credentials: SignupArgumentExternalCredentials | SignupArgumentEmailCredentials;
};

export function signUp(
  { email, gender, matchGender, name, birthdate, credentials, userNewId }: SignupArgument,
  metadata?: GrpcMetadata,
): Promise<SignUpResponse> {
  const profileBirthday = new ProfileBirthday({
    day: birthdate.day,
    month: birthdate.month,
    year: birthdate.year,
  });

  const request = new SignUpRequest({
    email,
    gender,
    matchGender,
    name,
    birthday: profileBirthday,
    userId: userNewId,
  });

  if (credentials.type === 'email') {
    request.emailCredential = new EmailCredential({
      email: credentials.email,
      password: credentials.password,
    });
  } else if (credentials.type === 'external') {
    request.externalCredential = new ExternalCredential({
      provider: credentials.provider,
      sessionId: credentials.sessionId,
    });
  }

  return client.signUp(request, {
    headers: metadata,
  });
}

export function updateProfile(
  field: ProfileField | ProfileField[],
): Promise<UpdateProfileResponse> {
  const request = new UpdateProfileRequest();
  if (field instanceof ProfileField) {
    request.fields = [field];
  } else {
    request.fields = field;
  }

  return client.updateProfile(request);
}

export function uploadPhoto(photoId: string): Promise<UploadPhotoResponse> {
  const request = new UploadPhotoRequest();
  request.photoId = photoId;
  return client.uploadPhoto(request);
}

export function getExternalUserInfo({
  provider,
  sessionId,
}: GetExternalUserInfoArgument): Promise<GetExternalUserInfoResponse> {
  const request = new GetExternalUserInfoRequest({
    provider,
    sessionId,
  });

  return client.getExternalUserInfo(request);
}

export function signIn(request: SignInRequest): Promise<SignInResponse> {
  return client.signIn(request);
}

export function sendPasswordRestoration(
  request: SendPasswordRestorationRequest,
): Promise<SendPasswordRestorationResponse> {
  return client.sendPasswordRestoration(request);
}

export function confirmNewPassword(
  request: ConfirmNewPasswordRequest,
): Promise<ConfirmNewPasswordResponse> {
  return client.confirmNewPassword(request);
}

export function getState(): Promise<GetStateResponse> {
  const request = new GetStateRequest();
  return client.getState(request);
}

export function getShortProfile(): Promise<GetShortProfileResponse> {
  const request = new GetShortProfileRequest();
  return client.getShortProfile(request);
}

export function updateAvatar(photoId: string): Promise<UpdateAvatarResponse> {
  const request = new UpdateAvatarRequest({
    photoId,
  });

  return client.updateAvatar(request);
}

export function getFullProfile(): Promise<GetFullProfileResponse> {
  const request = new GetFullProfileRequest();
  return client.getFullProfile(request);
}

export function deletePhoto(photoId: string): Promise<DeletePhotoResponse> {
  const request = new DeletePhotoRequest({
    photoId,
  });

  return client.deletePhoto(request);
}

export function deleteAccount(): Promise<DeleteAccountResponse> {
  const request = new DeleteAccountRequest();

  return client.deleteAccount(request);
}

export function signOut(): Promise<SignOutResponse> {
  const request = new SignOutRequest();

  return client.signOut(request);
}

export function getAccount(): Promise<GetAccountResponse> {
  const request = new GetAccountRequest();

  return client.getAccount(request);
}

// Validate email for such rules: format, already registered, etc.
export function validateEmail(email: string): Promise<ValidateEmailResponse> {
  const request = new ValidateEmailRequest({
    email,
  });

  return client.validateEmail(request);
}

// Does the same as `validateEmail`, but also checks if the email is not registered, but exists.
export function checkEmail(email: string): Promise<CheckEmailResponse> {
  const request = new CheckEmailRequest({
    email,
  });

  return client.checkEmail(request);
}

export function storeClientEvent(
  request: StoreClientEventRequest,
): Promise<StoreClientEventResponse> {
  return client.storeClientEvent(request);
}

export function submitOffboardingSurvey({
  response,
  type,
  responseDescription,
}: ExtractClassFields<SubmitOffboardingSurveyRequest>): Promise<SubmitOffboardingSurveyResponse> {
  const request = new SubmitOffboardingSurveyRequest({
    response,
    type,
    responseDescription,
  });

  return client.submitOffboardingSurvey(request);
}

export function getOneTimeHash(): Promise<GetOneTimeHashResponse> {
  const request = new GetOneTimeHashRequest();

  return client.getOneTimeHash(request);
}

export function deactivateAccount(seconds: number): Promise<DeactivateAccountResponse> {
  const duration = new Duration({
    seconds: BigInt(seconds),
  });

  const request = new DeactivateAccountRequest({
    deactivatedDuration: duration,
  });

  return client.deactivateAccount(request);
}

export function reactivateAccount(): Promise<ReactivateAccountResponse> {
  const request = new ReactivateAccountRequest();

  return client.reactivateAccount(request);
}

export function getFacebookTrackingParams(): Promise<GetFacebookTrackingParamsResponse> {
  const request = new GetFacebookTrackingParamsRequest();

  return client.getFacebookTrackingParams(request);
}

export function getIsSafe(eventName: string): Promise<GetIsSafeResponse> {
  const request = new GetIsSafeRequest({
    eventName,
  });

  return client.getIsSafe(request);
}

export function updateProfileLocation({
  location,
  source,
}: Required<
  ExtractClassFields<UpdateProfileLocationRequest>
>): Promise<UpdateProfileLocationResponse> {
  const profileLocation = new ProfileLocation({
    latitude: location.latitude,
    longitude: location.longitude,
    city: location.city,
    country: location.country,
    state: location.state,
  });

  const request = new UpdateProfileLocationRequest({
    location: profileLocation,
    source,
  });

  return client.updateProfileLocation(request);
}

export function listProfileTags(): Promise<ListProfileTagsResponse> {
  const request = new ListProfileTagsRequest();

  return client.listProfileTags(request);
}

function submitTrustPilotReview(
  arg: ExtractClassFields<SubmitTrustPilotReviewRequest>,
): Promise<SubmitTrustPilotReviewResponse> {
  const request = new SubmitTrustPilotReviewRequest({
    comment: arg.comment,
    score: arg.score,
  });

  return client.submitTrustPilotReview(request);
}

export const myProfileApi = {
  submitTrustPilotReview,
};
