import { I18n } from '@/services/i18n';

type Message = {
  getPremiumGold: string;
  premiumGold: string;
  continue: string;
};

const messages: I18n<Message> = {
  en: {
    getPremiumGold: 'Get Premium Gold',
    premiumGold: 'Premium Gold',
    continue: 'Continue',
  },
};

export { messages };
