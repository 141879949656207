import { I18n } from '@/services/i18n';

type Message = {
  cancelled: string;
  wouldYouLikeToTakeCancelled: string;
  reactivate: string;
  premiumStillActive: string;
};

const messages: I18n<Message> = {
  en: {
    cancelled: 'Your {tier} membership is cancelled',
    wouldYouLikeToTakeCancelled:
      'Would you like to take care of it and reactivate now to keep using the platform?',
    reactivate: 'reactivate',
    premiumStillActive:
      'Please note that you still have access to all premium features until {date}, so feel free to use the service.',
  },
};

export { messages };
