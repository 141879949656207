import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { replaceLegalVariables } from '@project-gd-x/shared-web/src/helpers/legal-formatter/legal-formatter';
import { computed } from 'vue';

import { PaymentFlow } from '@/components/payment/components/payment-method/helpers/type/type';
import { getFormattedCurrency } from '@/helpers/number';
import { useCountry } from '@/hooks/use-country/use-country';
import { getPackagePrice } from '@/pages/payment/helpers/package/package';
import { PaymentStatePackage } from '@/pages/payment/store/payment-state';
import { getTextPageModal } from '@/pages/text-page/helpers/text-page/text-page';
import { useSplitNtf } from '@/services/growth-book/hooks/use-split-ntf/use-split-ntf';
import { ExtractClassFields } from '@/type';

type PackageType = 'premium' | 'credits' | 'default';

type UseSelfArg = {
  paymentFlow: PaymentFlow;
  pkg: PaymentStatePackage;
  isAuthorizeCardToken: boolean;
};

export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------

  // Hook ----------------------------------------------------------------------
  const country = useCountry();

  const textPageModal = getTextPageModal();

  const splitNtf = useSplitNtf();

  // Computed ------------------------------------------------------------------
  const packageType = computed<PackageType>(() => {
    if (arg.pkg.id.includes('premium')) {
      return 'premium';
    }

    if (arg.pkg.id.includes('credits')) {
      return 'credits';
    }

    return 'default';
  });

  const legalAddress = computed<string>(() => {
    if (country.country.value) {
      return replaceLegalVariables('{{company}}, {{address}}', {
        countryAlpha3: country.country.value?.country.isoCodeAlpha3,
      });
    }
    return '';
  });

  const pkgNext = computed<ExtractClassFields<PremiumPackage> | null>(() => {
    if ((arg.pkg as ExtractClassFields<PremiumPackage>).nextPackage) {
      return (arg.pkg as ExtractClassFields<PremiumPackage>).nextPackage ?? null;
    }

    return null;
  });

  // Method --------------------------------------------------------------------
  function getPriceFormatted(pkg: PaymentStatePackage): string {
    if (pkg.id) {
      const price = getPackagePrice(pkg);

      return arg.isAuthorizeCardToken
        ? getFormattedCurrency(1, price.currency)
        : getFormattedCurrency(price.totalAmount, price.currency);
    } else {
      return '';
    }
  }

  async function init() {
    await country.load();
    await splitNtf.init();
  }

  return {
    country: country.country,
    textPageModal,
    splitNtf,
    packageType,
    legalAddress,
    pkgNext,
    getPriceFormatted,
    init,
  };
}
