<template>
  <PurchaseLayout
    v-if="selected"
    class="modal_credits premium-gold"
    :pkg="selected"
    @select="select"
    @close="close"
    @next="purchasePremium"
  >
    <template #title>{{ t('header') }}</template>

    <template #slider-content>
      <div class="explanatory_slider no-bg">
        <div class="explanatory_slider-avatar">
          <RetinaImage
            v-if="myProfile"
            class="img"
            :image-id="myProfile.avatar.id"
            :width="64"
            :height="64"
            format="png"
          />
        </div>
        <PurchaseSliderPremiumGold />
      </div>
    </template>

    <template #default>
      <div class="billng_tab flex justify-center">
        <div class="billng_tab-item !w-6/12" @click="goPurchasePremiumPage">
          <div class="billng_tab-title !text-tag !font-medium">{{ t('premium') }}</div>
        </div>
        <div class="billng_tab-item active !w-6/12">
          <div class="billng_tab-title !text-tag !font-medium">{{ t('premiumGold') }}</div>
        </div>
      </div>

      <PurchasePremiumItemDiscount
        v-for="pkg in packages"
        :key="pkg.id"
        v-model="selected"
        :pkg="pkg"
        :packages="packages"
      />

      <div v-if="selected?.price?.taxAmount" class="m-6 text-caption text-dark-200 text-center">
        You can cancel your subscription anytime you want in account settings. By tapping Continue,
        we will charge your card for {{ packageSelectedPriceFormatted }}, inclusive of taxes imposed
        according to the laws of your country, which constitute 20% of the total. Your subscription
        will automatically renew for the same package length you selected at the package price of
        {{ packageSelectedNextPriceFormatted }}. Our rules on refunds are provided in Membership
        Policy
      </div>
    </template>
  </PurchaseLayout>
</template>

<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, ref } from 'vue';

import { payWithCard } from '@/components/payment/helpers/payment-method/payment-method';
import RetinaImage from '@/components/retina-image/RetinaImage.vue';
import { getFormattedCurrency } from '@/helpers/number';
import { MyProfileStateShort } from '@/pages/my-profile/store/my-profile-state';
import PurchaseSliderPremiumGold from '@/pages/payment/components/layout/components/purchase-slider-premium-gold/PurchaseSliderPremiumGold.vue';
import PurchaseLayout from '@/pages/payment/components/layout/PurchaseLayout.vue';
import PurchasePremiumItemDiscount from '@/pages/payment/components/purchase/components/purchase-premium/components/purchase-premium-item-discount/PurchasePremiumItemDiscount.vue';
import { getNextPackagePrice, getPackagePrice } from '@/pages/payment/helpers/package/package';
import { PaymentStateCard, PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { pushRoute, replacePrevRoute } from '@/router';
import { useI18n } from '@/services/i18n';
import { dispatch, getStore } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const selected = ref<ExtractClassFields<PremiumPackage>>();
const status = ref<PaymentStateSubscription>();

const packages = ref<ExtractClassFields<PremiumPackage>[]>([]);

const defaultPaymentCard = computed<PaymentStateCard | undefined>(() => {
  return getStore().state.payment.paymentCards[0];
});

const myProfile = ref<MyProfileStateShort>();

const packageSelectedPriceFormatted = computed(() => {
  if (selected.value) {
    const price = getPackagePrice(selected.value);
    return getFormattedCurrency(price.totalAmount, price.currency);
  }
  return '';
});

const packageSelectedNextPriceFormatted = computed(() => {
  if (selected.value) {
    const price = getNextPackagePrice(selected.value);
    return getFormattedCurrency(price.totalAmount, price.currency);
  }
  return '';
});

function purchasePremium(): void {
  if (selected.value) {
    payWithCard({
      cardId: defaultPaymentCard.value?.id ?? '',
      pkg: selected.value,
    });
  }
}

function select() {
  emit('select', selected.value);
}

function close() {
  emit('close');
}

function goPurchasePremiumPage() {
  replacePrevRoute('paymentPurchasePremium');
}

const { t } = useI18n({ messages });

const emit = defineEmits(['select', 'close']);

dispatch('actionMyProfileGetShort').then((short) => {
  myProfile.value = short;
});

Promise.all([
  dispatch('actionPaymentListPremiumGoldPackages'),
  dispatch('actionPaymentGetSubscriptionStatus'),
]).then(([pkgs, st]) => {
  status.value = st as PaymentStateSubscription;

  packages.value = pkgs;
  // Second package should be selected.
  // [, selected.value] = packages.value;
  // TODO recheck
  [selected.value] = packages.value;

  if (status.value?.subscription?.isActive) {
    pushRoute('search');
  }
});
</script>
