<template>
  <ModalClose @close="emit('close')">
    <div v-if="self.step === 'thanks'" class="w-[328px]">
      <div class="px-6 pb-2 flex flex-col gap-2 items-center relative">
        <h4 class="text-button1 font-semibold text-dark">{{ t('thanks') }}</h4>
        <SvgIcon name="star_big" :width="124" :height="116" />
      </div>
      <button
        class="h-14 w-full border-t border-dark-500 text-button1 font-semibold text-primary"
        @click="emit('close')"
      >
        {{ t('great') }}
      </button>
    </div>
    <div v-else class="w-[328px] flex flex-col items-center gap-6">
      <div class="flex flex-col gap-2 items-center relative">
        <h4 class="text-button1 font-semibold text-dark">{{ t('enjoy') }}</h4>
        <p v-if="self.step === 'rate'" class="text-tag font-medium text-dark-100">
          {{ t('tap') }}
        </p>
        <ReviewRate v-model:rating="self.rating" @rated="self.handleRated" />
      </div>

      <Transition name="textarea">
        <textarea
          v-if="self.step === 'comment'"
          v-model="self.comment"
          cols="4"
          class="border border-dark-400 resize-none p-3 w-[296px] h-[144px] rounded text-body1 text-dark placeholder:text-dark-200"
          placeholder="(Optional)Tell what you think"
        ></textarea>
      </Transition>

      <button
        v-if="self.step === 'rate'"
        class="h-14 w-full border-t border-dark-500 text-button1 font-semibold text-dark-100"
        @click="emit('close')"
      >
        {{ t('notNow') }}
      </button>

      <ul v-else class="flex border-t w-full border-dark-500 text-button1 font-semibold">
        <li class="h-14 w-[50%] border-r border-dark-500">
          <button
            class="text-dark-100 w-full h-full flex justify-center items-center"
            @click="emit('close')"
          >
            {{ t('cancel') }}
          </button>
        </li>
        <li class="h-14 w-[50%]">
          <button
            class="text-primary w-full h-full flex justify-center items-center"
            @click="self.submitReview()"
          >
            {{ t('submit') }}
          </button>
        </li>
      </ul>
    </div>
  </ModalClose>
</template>

<script lang="ts" setup>
import SvgIcon from '@/components/inputs/icon/svg-icon/SvgIcon.vue';
import ModalClose from '@/components/modal/modal-close/ModalClose.vue';
import ReviewRate from '@/components/review-modal/components/review-rate/ReviewRate.vue';
import { useSelf } from '@/components/review-modal/hooks/use-self/use-self';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const emit = defineEmits(['close']);

const { t } = useI18n({ messages });

const self = useSelf({});
</script>

<style>
.textarea-enter-active,
.textarea-leave-active {
  transition: all 250ms ease-out;
}

.textarea-leave-active {
  position: absolute;
  top: 52px;
  left: 24px;
}

.textarea-enter-from,
.textarea-leave-to {
  opacity: 0;
  height: 0;
}
</style>
