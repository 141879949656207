import { ExternalUserInfoProvider } from '@/services/api/my-profile/helpers/type/type';

export function navigate(url: string): void {
  window.location.href = url;
}

export function navigateToExternalAuth(backUrl: string, provider: ExternalUserInfoProvider): void {
  const url = encodeURIComponent(backUrl);
  navigate(`https://${import.meta.env.VUE_APP_AUTH_HOST}/auth/begin/${provider}?return_url=${url}`);
}

export function getDocumentUrl(): string {
  return window.location.href;
}

export function replaceUrl(newUrl: string): void {
  window.history.replaceState(null, '', newUrl);
}

type ParamT = 't50' | '3dm' | '3dm3' | 'etf' | 'ntf3' | 'm1b';

export function isParamT(url: string, paramT: ParamT): boolean {
  return getUrlSearchParam(url, 't') === paramT;
}

export function getUrlSearchParam(url: string, key: string): string | null {
  try {
    const { searchParams } = new URL(url);
    return searchParams.get(key);
  } catch {
    return null;
  }
}
