import { I18n } from '@/services/i18n';

type Message = {
  greet: string;
  greetChatRequest: string;
  title: string;
  startChat: string;
  freeGiftIsAvailable: string;
  send: string;
  chatRequest: string;
};

const messages: I18n<Message> = {
  en: {
    greet:
      'Now {name} will see that you are interested in them. Draw attention to yourself with a welcome message.',
    greetChatRequest:
      'Now {name} will see that you are interested in chatting. You can send them 5 messages and wait for a response.',
    title: 'Nice move!',
    startChat: 'Start chat',
    chatRequest: 'Chat Request',
    // @TODO uncomment `freeGiftIsAvailable` when we'll have free gifts in the task below
    // https://gdx.myjetbrains.com/youtrack/issue/GDP-1203
    // freeGiftIsAvailable: 'Free gift is available for sending',
    freeGiftIsAvailable: 'Free gift is available for sending',
    send: 'SEND',
  },
};

export { messages };
