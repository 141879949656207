import { PackageIdentity } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';

import { formStyles } from '@/components/payment/components/payment-method/components/solid/solid-button/components/solid-credit-card-form/form-styles';
import {
  handleFormFail,
  handleFormSuccess,
  MessageFail,
  MessageInteraction,
  MessageSubmit,
  MessageSuccess,
} from '@/components/payment/components/payment-method/components/solid/solid-button/components/solid-credit-card-form/helpers/form-handler/form-handler';
import { loadScript } from '@/helpers/script/script';
import { trackPaymentClick, trackPaymentSubmit } from '@/helpers/track/track-payment/track-payment';
import {
  PaymentStatePackage,
  PaymentStateSolidPaymentForm,
} from '@/pages/payment/store/payment-state';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { SETTINGS_DEFAULT_CURRENCY, SETTINGS_SOLID_FORM_API_URL } from '@/settings';
import { dispatch } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

type InitSolidFormArg = {
  handleFormMounted: () => void;
  pkg: PaymentStatePackage;
  btnSubmitText: string;
  isCardEnabled: boolean;
  isGooglePayEnabled: boolean;
  isApplePayEnabled: boolean;
  isAuthorizeCardToken: boolean;
  containerId: string;
  googlePayContainerId: string;
  applePayContainerId: string;
};
export async function initSolidForm(arg: InitSolidFormArg) {
  let timerId: number | null = null;
  try {
    await loadScript(SETTINGS_SOLID_FORM_API_URL);

    let merchantData: PaymentStateSolidPaymentForm | null = null;
    if (arg.isAuthorizeCardToken) {
      merchantData = await getMerchantDataSolidAuthorizeForm();
    } else {
      merchantData = await getMerchantDataSolidPaymentForm({
        id: arg.pkg.id,
        offerId: arg.pkg.offer?.id ?? '',
      });
    }

    const formData = {
      styles: formStyles(),
      formParams: {
        enabled: arg.isCardEnabled,
        submitButtonText: arg.btnSubmitText,
        isCardHolderVisible: true,
        // headerText: t('formHeader'),
        // titleText: t('formTitle'),
        formTypeClass: 'default',
        // secureBrands: ['visa-secure', 'mcc-id-check', 'ssl', 'pci-dss', 'norton', 'mc-affee'],
      },
      iframeParams: {
        containerId: arg.containerId,
      },
      googlePayButtonParams: {
        enabled: arg.isGooglePayEnabled,
        containerId: arg.googlePayContainerId,
      },
      applePayButtonParams: {
        enabled: arg.isApplePayEnabled,
        containerId: arg.applePayContainerId,
      },
    };

    // https://dev.solidgate.com/developers/documentation/solid-payment-form
    const form = window.PaymentFormSdk.init({ merchantData, ...formData });

    console.log('FORM INITED', JSON.parse(JSON.stringify({ merchantData, ...formData })));

    form.on('mounted', () => arg.handleFormMounted());

    form.on('interaction', ({ data }: { data: MessageInteraction }) => {
      if (data.target.interaction === 'click' && data.target.type === 'button') {
        switch (data.target.name) {
          case 'submit':
            trackPaymentClick({ method: 'solid-form' });
            break;

          case 'paypal':
            trackPaymentClick({ method: 'paypal' });
            break;

          case 'applePay':
            trackPaymentClick({ method: 'apple-pay' });
            break;

          case 'googlePay':
            trackPaymentClick({ method: 'google-pay' });
            break;
        }
      }
    });

    form.on('submit', ({ data }: { data: MessageSubmit }) => {
      switch (data.entity) {
        case 'form':
          trackPaymentSubmit({ method: 'solid-form' });
          break;

        case 'paypal':
          trackPaymentSubmit({ method: 'paypal' });
          break;

        case 'applebtn':
          trackPaymentSubmit({ method: 'apple-pay' });
          break;

        case 'googlebtn':
          trackPaymentSubmit({ method: 'google-pay' });
          break;

        case 'resign':
          trackPaymentSubmit({ method: 'resign' });
          break;
      }
    });

    // https://dev.solidgate.com/developers/documentation/payment-form#events
    form.on('success', ({ data }: { data: MessageSuccess }) => {
      if (data.entity === 'form') {
        timerId = window.setTimeout(async () => {
          await handleFormSuccess({
            data,
            price: Number(arg.pkg?.price?.totalAmount ?? 0n),
            currency: arg.pkg?.price?.currency ?? SETTINGS_DEFAULT_CURRENCY,
          });
        }, 2000);
      }
    });

    // https://dev.solidgate.com/developers/documentation/payment-form#events
    form.on('fail', ({ data }: { data: MessageFail }) => {
      if (data.entity === 'form') {
        timerId = window.setTimeout(async () => {
          console.log('FORM FAIL ', JSON.parse(JSON.stringify(data)));

          await handleFormFail({
            data,
          });
        }, 2000);
      }
    });
  } catch (error) {
    sentryCaptureMessage({
      message: 'Failed to load Solid Script',
      captureContext: { extra: { error } },
    });
  }

  return {
    destroyForm() {
      if (timerId) {
        window.clearTimeout(timerId);
      }
    },
  };
}

export function getMerchantDataSolidAuthorizeForm(): Promise<PaymentStateSolidPaymentForm> {
  return dispatch('actionPaymentGetSolidAuthorizeForm');
}

export function getMerchantDataSolidPaymentForm(
  identity: ExtractClassFields<PackageIdentity>,
): Promise<PaymentStateSolidPaymentForm> {
  return dispatch('actionPaymentGetSolidPaymentForm', identity);
}
