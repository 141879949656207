<template>
  <ModalHeaderContent>
    <template #left>
      <Close @click="emit('close')" />
    </template>

    <template #center>
      <slot name="title">{{ t('creditCard') }}</slot>
    </template>
    <template #default>
      <SnackButton
        v-if="paymentFlow === 'add-new-payment-method'"
        button-type="info"
        button-style="info"
      >
        <template #icon>
          <FontIcon class="icon-lock_solid solid-card-form-icon" />
        </template>
        <template #default>{{ t('info') }}</template>
        <template #button-right><div></div></template>
      </SnackButton>

      <!--      <SolidPaymentMethod-->
      <!--        :pkg="pkg"-->
      <!--        :is-card-enabled="true"-->
      <!--        :is-google-pay-enabled="false"-->
      <!--        :is-apple-pay-enabled="false"-->
      <!--        :is-authorize-card-token="self.isAuthorizeCardToken"-->
      <!--      />-->

      <slot></slot>

      <PaymentMethodSecureInfo />

      <div class="payment-secure"><i class="icon icon-lock"></i>{{ t('secureCheckout') }}</div>

      <div v-if="paymentFlow !== 'add-new-payment-method'" class="payment-of form-text text-center">
        <PaymentMethodPolicyNtf
          v-if="self.packageType !== 'credits' && self.splitNtf.variant !== 'def' && self.pkgNext"
          :price="self.getPriceFormatted(pkg)"
          :price-next="self.getPriceFormatted(self.pkgNext)"
          :label="self.pkgNext?.label ?? ''"
          @open-membership-policy="self.textPageModal.showModal('membership')"
        />
        <PaymentMethodPolicyPremium
          v-else-if="self.packageType === 'premium'"
          :price="self.getPriceFormatted(pkg)"
          @open-membership-policy="self.textPageModal.showModal('membership')"
        />
        <PaymentMethodPolicyCredits
          v-else-if="self.packageType === 'credits'"
          :price="self.getPriceFormatted(pkg)"
          @open-membership-policy="self.textPageModal.showModal('membership')"
        />
        <PaymentMethodPolicyTrial
          v-else-if="self.pkgNext"
          :price-next="self.getPriceFormatted(self.pkgNext)"
          :price="self.getPriceFormatted(pkg)"
        />

        <div v-if="self.country" class="mt-[5px]">
          <template v-if="self.country.country.isoCodeAlpha3 === 'USA'">
            {{ t('yourCreditCardWillBeBilled') }}<br />
            {{ self.legalAddress }}
          </template>
          <template v-else>{{ self.legalAddress }}</template>
        </div>
      </div>
    </template>
  </ModalHeaderContent>
</template>

<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: true, isForceAllowedModal: true };
</script>

<script lang="ts" setup>
import { PropType, reactive } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import Close from '@/components/layout/components/header/components/close/Close.vue';
import ModalHeaderContent from '@/components/modal/ModalHeaderContent.vue';
import PaymentMethodPolicyCredits from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-policy-credits/PaymentMethodPolicyCredits.vue';
import PaymentMethodPolicyNtf from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-policy-ntf/PaymentMethodPolicyNtf.vue';
import PaymentMethodPolicyPremium from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-policy-premium/PaymentMethodPolicyPremium.vue';
import PaymentMethodPolicyTrial from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-policy-trial/PaymentMethodPolicyTrial.vue';
import PaymentMethodSecureInfo from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-secure-info/PaymentMethodSecureInfo.vue';
import { PaymentFlow } from '@/components/payment/components/payment-method/helpers/type/type';
import SnackButton from '@/components/snack-button/SnackButton.vue';
import { PaymentStatePackage } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';

import { useSelf } from './hooks/use-self/use-self';
import { messages } from './i18n';

const props = defineProps({
  paymentFlow: {
    type: String as PropType<PaymentFlow>,
    required: true,
  },
  pkg: {
    type: Object as PropType<PaymentStatePackage>,
    required: true,
    default: () => ({}),
  },
  isAuthorizeCardToken: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const { t } = useI18n({ messages });

const self = reactive(
  useSelf({
    paymentFlow: props.paymentFlow,
    pkg: props.pkg,
    isAuthorizeCardToken: props.isAuthorizeCardToken,
  }),
);

self.init();
</script>

<style lang="scss">
.solid-card-form-icon {
  font-size: 25px;
}
</style>
