import { ref } from 'vue';

import { isParamT } from '@/helpers/navigator/navigator';
import { getGrowthBookFeatureValue, GrowthBookFeatures } from '@/services/growth-book/growth-book';
import { getState } from '@/store/store-helper';

export function useSplitNtf() {
  // Ref -----------------------------------------------------------------------
  const variant = ref<GrowthBookFeatures['ntf21']>('def');

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  async function init() {
    if (isParamT(getState().common.index.landingUrl, 'ntf3')) {
      variant.value = await getGrowthBookFeatureValue('ntf21', 'def');
      // variant.value = 'v1';
    }
  }

  return {
    variant,
    init,
  };
}
