import { I18n } from '@/services/i18n';

type Message = {
  popular: string;
  save: string;
  best: string;
  pricePerWeek: string;
  idealBalance: string;
  tax: string;
};

const messages: I18n<Message> = {
  en: {
    popular: 'Popular',
    save: 'Save',
    best: 'Best deal',
    pricePerWeek: '{price}/week',
    idealBalance: 'Ideal balance',
    tax: 'tax',
  },
};

export { messages };
