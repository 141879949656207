import dayjs from 'dayjs';

import { getUserId, isUserLoggedIn } from '@/helpers/auth/auth';
import { getCache, hasCache, setCache } from '@/helpers/persistent-cache';
import { dispatch } from '@/store/store-helper';

type SmartlookAction = 'init' | 'identify' | 'record';

declare global {
  interface Window {
    smartlook: (
      action: SmartlookAction,
      identity: string | Record<string, unknown>, // it might be `userId` or `projectId` depends on `action` parameter
      customParams?: Record<string, unknown>,
    ) => void;
  }
}

const PROJECT_KEY = '733ae949c1be4768e508d1f615b26c28be728ae9';
const ENABLED_CACHE_KEY = 'smartlook_enabled';
const RECORDINGS_PERCENT = 10;

// https://web.developer.smartlook.com/docs
function loadScript(): void {
  window.smartlook ||
    (function (d) {
      const o = (window.smartlook = function () {
          // @ts-expect-error: vendor script
          // eslint-disable-next-line
          o.api.push(arguments);
        }),
        h = d.getElementsByTagName('head')[0];
      const c = d.createElement('script');
      // @ts-expect-error: vendor script
      o.api = [];
      c.async = true;
      c.type = 'text/javascript';
      c.charset = 'utf-8';
      c.src = 'https://rec.smartlook.com/recorder.js';
      h.appendChild(c);
    })(document);
  window.smartlook('init', PROJECT_KEY);
  window.smartlook('record', { forms: true, numbers: true });
}

// https://web.developer.smartlook.com/docs/identify-visitor
export function identifyUser(userId: string): void {
  window.smartlook('identify', userId);
}

export function setIsEnabled(value: boolean): void {
  setCache(ENABLED_CACHE_KEY, value ? '1' : '0');
}

export function getIsEnabled(): boolean {
  return getCache(ENABLED_CACHE_KEY) === '1';
}

export function hasIsEnabled(): boolean {
  return hasCache(ENABLED_CACHE_KEY);
}

export function getIsRandomlyAllowed(): boolean {
  const isEnvEnabled = +import.meta.env.VUE_APP_IS_SMARTLOOK_ENABLED !== 0;
  const isRandomlyAllowed = Math.random() * 100 < RECORDINGS_PERCENT;

  return isEnvEnabled && isRandomlyAllowed;
}

export async function getIsUserRecentlyRegistered(): Promise<boolean> {
  if (isUserLoggedIn()) {
    const profile = await dispatch('actionMyProfileGetFull');

    // Profile must be registered less than 1 day ago
    const timeCreated = dayjs.unix(profile.timeCreated);
    const timeNow = dayjs();
    const timeDiff = timeNow.diff(timeCreated, 'hours');
    return timeDiff < 24;
  }

  return false;
}

let isInitialized = false;
export async function runSmartLook(): Promise<void> {
  if (!isUserLoggedIn() || isInitialized) {
    return;
  }

  if (!hasIsEnabled()) {
    const isEnabled = getIsRandomlyAllowed() && (await getIsUserRecentlyRegistered());
    setIsEnabled(isEnabled);
  }

  if (getIsEnabled()) {
    loadScript();
    identifyUser(getUserId());
  }

  isInitialized = true;
}
