import { I18n } from '@/services/i18n';

type Message = {
  suspended: string;
  wouldYouLikeToTakeCancelled: string;
  reactivate: string;
};

const messages: I18n<Message> = {
  en: {
    suspended: 'Your {tier}<br />membership is suspended',
    wouldYouLikeToTakeCancelled:
      'Would you like to take care of it and reactivate now to keep using the platform?',
    reactivate: 'reactivate',
  },
};

export { messages };
