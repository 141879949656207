import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { PromoType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/promo_pb';
import { computed } from 'vue';

import ModalSpinner from '@/components/modal/ModalSpinner.vue';
import { hideSubscriptionPromoModal } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/subscription-promo-helper';
import { getFormattedCurrency } from '@/helpers/number';
import { MyProfileStateShort } from '@/pages/my-profile/store/my-profile-state';
import { getNextPackagePrice, getPackagePrice } from '@/pages/payment/helpers/package/package';
import { isModalVisible, useModal } from '@/services/modal/modal';
import { SETTINGS_DEFAULT_CURRENCY } from '@/settings';
import { CommonStatePromoModal } from '@/store/modules/common/common-state';
import { dispatch, getStore } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

export function useSubscriptionPromoTrial(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emit: (...args: any[]) => void,
) {
  const profile = computed((): MyProfileStateShort => {
    return getStore().state.myProfile.short;
  });

  const promoModal = computed<CommonStatePromoModal>(() => {
    return getStore().state.common.promoModal;
  });

  const pkg = computed((): ExtractClassFields<PremiumPackage> => {
    return (
      promoModal.value.packages.find((pkg) => {
        return pkg.isPopular;
      }) || promoModal.value.packages[0]
    );
  });

  const price = computed((): string => {
    const pkgPrice = getPackagePrice(pkg.value);

    return getFormattedCurrency(
      pkgPrice?.totalAmount ?? 0n,
      pkgPrice?.currency ?? SETTINGS_DEFAULT_CURRENCY,
    );
  });

  const priceOld = computed((): string => {
    return getFormattedCurrency(
      pkg.value.price?.baseAmount ?? 0n,
      pkg.value.price?.currency ?? SETTINGS_DEFAULT_CURRENCY,
    );
  });

  const nextPackagePrice = computed((): string => {
    const price = getNextPackagePrice(pkg.value);
    return getFormattedCurrency(price.totalAmount, price.currency);
  });

  const pricePerWeek = computed((): string => {
    return getFormattedCurrency(
      pkg.value.pricePerWeek?.totalAmount ?? 0n,
      pkg.value.price?.currency ?? SETTINGS_DEFAULT_CURRENCY,
    );
  });

  const isCloseVisible = computed((): boolean => {
    return promoModal.value.type === PromoType.TRIAL;
  });

  function close(): void {
    hideSubscriptionPromoModal();
    emit('close');
  }

  function next(): void {
    emit('next', pkg.value);
  }

  async function handleDataLoading(isLoader = true): Promise<void> {
    if (isLoader) showModal();
    await dispatch('actionMyProfileGetShort');
    if (isLoader) hideModal();
  }

  const { showModal, hideModal } = useModal(() => ({
    component: ModalSpinner,
  }));

  return {
    profile,
    promoModal,
    pkg,
    price,
    priceOld,
    nextPackagePrice,
    pricePerWeek,
    isCloseVisible,
    close,
    next,
    handleDataLoading,
    showModal,
    hideModal,
    isModalVisible,
  };
}
