import { I18n } from '@/services/i18n';

type Message = {
  messageFrom: string;
  giftFrom: string;
  photoFrom: string;
  iHaveSentGift: string;
  iHaveSentPhoto: string;
  reply: string;
  premiumContent: string;
};

const messages: I18n<Message> = {
  en: {
    messageFrom: 'Message from',
    giftFrom: 'Gift from',
    photoFrom: 'Photo from',
    iHaveSentGift: 'I’ve sent a gift to you. Please, check it.',
    iHaveSentPhoto: 'I’ve sent a photo to you. Please, check it.',
    reply: 'Reply',
    premiumContent: 'Premium content',
  },
};

export { messages };
