<template>
  <SubscriptionPromoLayoutRedesign
    background-class="bg-trial-3 limited promo-redesign slider_vertical_mode"
    data-test-id="trial_popup"
    @close="emit('close')"
  >
    <template #title>
      <div class="modal_default-sub_title">{{ t('trialOfferUnlocked') }}</div>
      <div class="modal_default-title_1">
        <span v-html="pkg.label + t('headerPerWeek', { price })"></span>
      </div>
    </template>

    <template #default>
      <SpinnerButton
        v-if="cardDefault"
        :loading-duration-ms="10000"
        class="btn btn-block btn-primary mb-0"
        @click="next"
      >
        {{ t('continue') }}
      </SpinnerButton>

      <button v-else type="button" class="btn btn-block btn-primary mb-0" @click="next">
        {{ t('continue') }}
      </button>

      <div v-if="!isFromLanding" class="text-overline text-dark-200 text-center mt-3">
        <PaymentMethodPolicyTrial
          :price-next="priceNext"
          :price="price"
          :btn-text="t('continue')"
        />
      </div>
    </template>
  </SubscriptionPromoLayoutRedesign>
</template>

<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, PropType } from 'vue';

import SpinnerButton from '@/components/inputs/spinner-button/SpinnerButton.vue';
import PaymentMethodPolicyTrial from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-policy-trial/PaymentMethodPolicyTrial.vue';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { getFormattedCurrency } from '@/helpers/number';
import { trackPaymentPromo } from '@/helpers/track/track-payment/track-payment';
import SubscriptionPromoLayoutRedesign from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-layout-redesign/SubscriptionPromoLayoutRedesign.vue';
import {
  getNextPackagePrice,
  getPackagePopular,
  getPackagePrice,
} from '@/pages/payment/helpers/package/package';
import { PaymentStateCard } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';
import { SETTINGS_DEFAULT_CURRENCY } from '@/settings';
import { getState, getStore } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const { t } = useI18n({ messages });

const props = defineProps({
  cardDefault: {
    type: Object as PropType<PaymentStateCard>,
    default: undefined,
  },
  source: {
    type: String as PropType<PromoShowPurchaseSource>,
    required: true,
  },
});

const emit = defineEmits(['next', 'close']);

const isFromLanding = computed((): boolean => {
  return getState().common.index.isFromLanding;
});

const pkg = computed<ExtractClassFields<PremiumPackage>>(() =>
  getPackagePopular(getStore().state.common.promoModal.packages),
);

const price = computed<string>(() => {
  const pkgPrice = getPackagePrice(pkg.value);
  return getFormattedCurrency(
    pkgPrice?.totalAmount ?? 0n,
    pkgPrice?.currency ?? SETTINGS_DEFAULT_CURRENCY,
  );
});

const priceNext = computed<string>(() => {
  const pkgNextPrice = getNextPackagePrice(pkg.value.nextPackage!);

  return getFormattedCurrency(
    pkgNextPrice?.totalAmount ?? 0n,
    pkgNextPrice?.currency ?? SETTINGS_DEFAULT_CURRENCY,
  );
});

function next(): void {
  emit('next', pkg.value);
}

trackPaymentPromo({
  from: props.source,
  popup: 'trial-ntf-v1',
  type: 'premium',
});
</script>

<style lang="scss" src="./style.scss"></style>
