import { I18n } from '@/services/i18n';

type Message = {
  yourPhotoWasRequested: string;
  wantsToSeeWhatYouLook: string;
  uploadPhoto: string;
};

const messages: I18n<Message> = {
  en: {
    yourPhotoWasRequested: 'Your photo was requested by...',
    wantsToSeeWhatYouLook: 'wants to see what you look like and asked you to upload a photo',
    uploadPhoto: 'Upload photo',
  },
};

export { messages };
