import { I18n } from '@/services/i18n';

type Message = {
  continue: string;
};

const messages: I18n<Message> = {
  en: {
    continue: 'Continue',
  },
};

export { messages };
