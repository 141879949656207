import { computed, ref } from 'vue';

import {
  TrialProsperiLookingFor,
  TrialProsperiType,
} from '@/components/ntf-modal/components/ntf-prosperi-v1/helpers/type/type';
import { useNtfTimer } from '@/components/ntf-modal/hooks/use-ntf-timer/use-ntf-timer';
import {
  loadCards,
  paymentPurchase,
} from '@/components/payment/helpers/payment-method/payment-method';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { getUrlSearchParam } from '@/helpers/navigator/navigator';
import { trackPaymentPromo } from '@/helpers/track/track-payment/track-payment';
import { useSubscriptionPromoTrial } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-trial/hooks/use-subscription-promo-trial/use-subscription-promo-trial';
import { PaymentStateCard } from '@/pages/payment/store/payment-state';
import { getState } from '@/store/store-helper';

type UseSelfArg = {
  type: TrialProsperiType;
  source: PromoShowPurchaseSource;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const lookingFor = ref<TrialProsperiLookingFor>('skip');
  const cardDefault = ref<PaymentStateCard>();
  const isButtonLoading = ref(false);

  // Hook ----------------------------------------------------------------------
  const timer = useNtfTimer();
  const promoTrial = useSubscriptionPromoTrial(() => {});

  // Computed ------------------------------------------------------------------
  const isFromLanding = computed((): boolean => {
    return getState().common.index.isFromLanding;
  });

  // Method --------------------------------------------------------------------
  function initLookingFor() {
    const landingUrl = getState().common.index.landingUrl;
    const searchParamLookingFor = getUrlSearchParam(landingUrl, 'relationships');
    if (searchParamLookingFor) {
      lookingFor.value = searchParamLookingFor as TrialProsperiLookingFor;
    }
  }

  function purchasePkg() {
    paymentPurchase({
      pkg: promoTrial.pkg.value,
    });
  }

  function init() {
    timer.init();
    initLookingFor();

    loadCards().then((cardList) => {
      cardDefault.value = cardList[0];
    });

    trackPaymentPromo({
      from: arg.source,
      popup: 'prosperi-ntf-v1',
      type: 'premium',
    });
  }

  function destroy() {
    timer.destroy();
  }

  return {
    lookingFor,
    cardDefault,
    isButtonLoading,
    promoTrial,
    isFromLanding,
    timer,
    purchasePkg,
    init,
    destroy,
  };
}
