import { I18n } from '@/services/i18n';

type Message = {
  trial: string;
  premium: string;
  premiumGold: string;
};

const messages: I18n<Message> = {
  en: {
    trial: 'trial',
    premium: 'premium',
    premiumGold: 'premium Gold',
  },
};

export { messages };
