import { Notification } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/notification/v1/notification_pb';
import Centrifuge from 'centrifuge/build/protobuf';
import { Subscription } from 'centrifuge/build/subscription';

import { getAccessToken, getRefreshToken, getUserId, isUserLoggedIn } from '@/helpers/auth/auth';
import { getMergeNotificationsSplitIsV2 } from '@/helpers/split/split';
import { updateRefreshToken } from '@/helpers/token/token';
import { debounce } from '@/helpers/you-dont-need-lodash/you-dont-need-lodash';
import { sendDevtoolsGrpcMessage } from '@/services/api/interceptor/interceptor-devtools/interceptor-devtools';

export type NotificationHandler = (notification: Notification) => void;

declare global {
  interface Window {
    debugWebsocket: boolean;
  }
}

let centrifuge: Centrifuge | null = null;
let sub: Subscription | null = null;
const notificationHandlers: NotificationHandler[] = [];

type DebouncedNotifications = Record<
  NonNullable<Notification['notification']['case']>,
  (notification: Notification) => void
>;

const debouncedNotifications: DebouncedNotifications = {} as DebouncedNotifications;

function handleNotification(notification: Notification): void {
  getMergeNotificationsSplitIsV2().then((isOn) => {
    if (isOn) {
      notificationHandlers.forEach((handler) => handler(notification));
    } else {
      const key = notification.notification.case;

      if (key) {
        if (!debouncedNotifications[key]) {
          debouncedNotifications[key] = debounce((notification: Notification) => {
            notificationHandlers.forEach((handler) => handler(notification));
          }, 500);
        }

        debouncedNotifications[key](notification);
      }
    }
  });
}

/*
 * @param force - force to recreate connection to websocket server, used when
 * user refreshes token.
 */
export function connectToWebsocketServer(): Centrifuge | null {
  if (!centrifuge && isUserLoggedIn()) {
    centrifuge = new Centrifuge(import.meta.env.VUE_APP_WEBSOCKET_URL, {
      protocol: 'protobuf',
      token: getAccessToken(),
      getToken: async () => {
        await updateRefreshToken(getRefreshToken());
        return getAccessToken();
      },
    });

    centrifuge.on('disconnected', (error) => {
      console.info('disconnected', error);
    });

    centrifuge.on('error', (error) => {
      console.info('error', error);
    });

    centrifuge.on('leave', (error) => {
      console.info('leave', error);
    });

    centrifuge.on('unsubscribed', (error) => {
      console.info('unsubscribed', error);
    });
  }

  if (centrifuge) {
    // It won't connect if connection is already exists.
    centrifuge.connect();

    const userId = getUserId();
    const channelName = `app:Notification#${userId}`;

    sub = centrifuge.getSubscription(channelName);

    if (!sub) {
      sub = centrifuge.newSubscription(channelName);
      sub.on('publication', (response) => {
        const notification = Notification.fromBinary(response.data);

        sendDevtoolsGrpcMessage({
          method: 'WS_' + notification.notification.case,
          response: notification.notification.value,
        });

        handleNotification(notification);
      });
    }

    // It won't subscribe if subscription is already exists.
    sub.subscribe();
  }

  return centrifuge;
}

export function disconnectFromWebsocketServer(): void {
  sub?.unsubscribe();
  sub = null;

  centrifuge?.disconnect();
  centrifuge = null;
}

export function addNotificationHandler(handler: NotificationHandler): NotificationHandler {
  notificationHandlers.push(handler);

  return handler;
}

export function removeNotificationHandler(handler: NotificationHandler): void {
  const index = notificationHandlers.findIndex((h) => h === handler);

  if (index !== -1) {
    notificationHandlers.splice(index, 1);
  }
}

export function getCentrifuge(): Centrifuge | null {
  return centrifuge;
}

export function getSubscription(): Subscription | null {
  return sub;
}
