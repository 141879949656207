import { Conversion } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/conversion/v1/conversion_pb';

import { isUserLoggedIn } from '@/helpers/auth/auth';
import {
  trackFacebookPixel,
  FacebookTrackEventName,
} from '@/helpers/facebook-pixel/facebook-pixel';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { hasCache, setCache } from '@/helpers/persistent-cache';
import { updateLastDeliveredTime } from '@/services/api/conversion/conversion';
import { dispatch } from '@/store/store-helper';

const CACHE_PREFIX = 'conversion_';

function resolveConversionsFacebookTracking(conversions: Conversion[]): void {
  conversions.forEach((conversion) => {
    if (conversion.shouldSend) {
      const event = conversion.name as FacebookTrackEventName;
      const params = Object.fromEntries(conversion.params.map((p) => [p.key, p.value]));
      trackFacebookPixel({
        event,
        // @ts-expect-error: any
        params,
        eventId: conversion.id,
      });
    }
  });
}

async function updateLastConversionDeliveredTime(conversions: Conversion[]) {
  let maxSeconds = 0n;
  let maxNanos = 0;

  conversions.forEach((conversion) => {
    const seconds = conversion.createdTime?.seconds ?? 0n;
    const nanos = conversion.createdTime?.nanos ?? 0;
    if (seconds > maxSeconds) {
      maxSeconds = seconds;
      maxNanos = nanos;
    } else if (seconds === maxSeconds) {
      if (nanos > maxNanos) {
        maxNanos = nanos;
      }
    }
  });

  if (maxSeconds === 0n) {
    maxSeconds = BigInt(Math.round(+new Date().getTime() / 1000));
  }

  if (conversions.length > 0) {
    await updateLastDeliveredTime({
      seconds: maxSeconds,
      nanos: maxNanos,
    });
  }
}

export async function handleConversions() {
  if (!isUserLoggedIn()) {
    return;
  }

  await dispatch('actionPaymentListConversions').then(async (conversions) => {
    const fbConversions = conversions.filter((conversion) => conversion.network === 'facebook');

    if (fbConversions.length) {
      resolveConversionsFacebookTracking(fbConversions);
    }

    // https://gdx.myjetbrains.com/youtrack/issue/GDP-3914/frontend-Add-Subscribe-event
    conversions.forEach((conversion) => {
      if (conversion.shouldSend) {
        const event = conversion.name as FacebookTrackEventName;

        try {
          const cacheKey = CACHE_PREFIX + event;
          const hasEvent = hasCache(cacheKey);

          if (event === 'Subscribe' && !hasEvent) {
            customEventTrigger({
              event: CACHE_PREFIX + event,
            });
            setCache(cacheKey, '1');
          }
        } catch {
          console.log('Error: realtime facebook tracking');
        }
      }
    });

    if (conversions.length) {
      await updateLastConversionDeliveredTime(conversions);
    }
  });
}
