import { I18n } from '@/services/i18n';

type Message = {
  text: string;
};

const messages: I18n<Message> = {
  en: {
    text: 'Your account has been deactivated',
  },
};

export { messages };
