// https://www.geekstrick.com/snippets/how-to-parse-cookies-in-javascript/
export function parseCookie(str: string): { [key: string]: string } {
  return str
    .split(';')
    .map((v) => v.split('='))
    .filter((v) => v.length > 1)
    .reduce(
      (acc, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
        return acc;
      },
      {} as { [key: string]: string },
    );
}
