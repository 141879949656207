import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  description: string;
  action: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Romantic compatibility with...',
    description: '{name} checked their romantic compatibility with you',
    action: 'Meet {name}',
  },
};

export { messages };
