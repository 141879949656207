import { ActivityItem, ActivityState } from './activity-state';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationActivityAddIncoming: ActivityItem[];
    mutationActivityAddOutgoing: ActivityItem[];
    mutationActivityClearIncoming: never;
    mutationActivityClearOutgoing: never;
    mutationActivityLike: string;
    mutationActivitySetHasUnread: boolean;
  }
}

export function mutationActivityAddIncoming(state: ActivityState, incoming: ActivityItem[]) {
  state.incoming.push(...incoming);
}

export function mutationActivityAddOutgoing(state: ActivityState, outgoing: ActivityItem[]) {
  state.outgoing.push(...outgoing);
}

export function mutationActivityClearIncoming(state: ActivityState) {
  state.incoming = [];
}

export function mutationActivityClearOutgoing(state: ActivityState) {
  state.outgoing = [];
}

export function mutationActivityLike(state: ActivityState, userId: string) {
  function activityLike(activities: ActivityItem[]) {
    activities
      .filter((activity) => activity.userId === userId)
      .forEach((activity) => {
        if (activity.data.type === 'visit' || activity.data.type === 'like') {
          // eslint-disable-next-line no-param-reassign
          activity.data.isLiked = true;
        }
      });
  }

  activityLike(state.incoming);
  activityLike(state.outgoing);
}

export function mutationActivitySetHasUnread(state: ActivityState, hasUnreadActivities: boolean) {
  state.hasUnreadActivities = hasUnreadActivities;
}
