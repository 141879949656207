<template>
  <div class="footer_panel">
    <div class="gift_tab">
      <div class="gift_tab-item" :class="{ active: !isPremium }" @click="isPremium = false">
        {{ t('basic') }}
      </div>
      <div class="gift_tab-item" :class="{ active: isPremium }" @click="isPremium = true">
        {{ t('premium') }}
      </div>
    </div>

    <div class="footer_panel-scroll flex flex-col">
      <div v-for="(subcategory, key) in category" :key="key">
        <div class="gift_pack-title">{{ t(key + '') }}</div>
        <div class="gift_list">
          <ChatFooterGiftsItem
            v-for="gift in subcategory"
            :key="gift.id"
            :gift="gift"
            @open-gift-modal="openGiftModal"
            @send-gift="sendGift"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { DialogGift } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/dialogs_pb';
import { computed, PropType, ref } from 'vue';

import ChatBuyFreeGiftModal from '@/pages/chat/components/chat-view/components/chat-buy-free-gift-modal/ChatBuyFreeGiftModal.vue';
import ChatFooterGiftsItem from '@/pages/chat/components/chat-view/components/chat-footer/components/chat-footer-gifts/components/chat-footer-gifts-item/ChatFooterGiftsItem.vue';
import Purchase from '@/pages/payment/components/purchase/Purchase.vue';
import { useI18n } from '@/services/i18n';
import { useModal } from '@/services/modal/modal';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

type GiftsByCategories = {
  [kye: string]: {
    [key: string]: ExtractClassFields<DialogGift>[];
  };
};

const props = defineProps({
  gifts: {
    type: Object as PropType<ExtractClassFields<DialogGift>[]>,
    required: true,
  },
});

const emit = defineEmits(['send']);

const { t } = useI18n({ messages });
const isPremium = ref(false);
const selectedGift = ref<ExtractClassFields<DialogGift>>();

const giftsDividedByCategories = computed((): GiftsByCategories => {
  return props.gifts.reduce((acc: GiftsByCategories, current: ExtractClassFields<DialogGift>) => {
    const { categoryId: category, subcategoryId: sub } = current;

    if (acc[category] && acc[category][sub]) {
      acc[category][sub].push(current);
    } else {
      acc[category] = { ...acc[category], [sub]: [current] };
    }

    return acc;
  }, {});
});

const category = computed(() => {
  return isPremium.value
    ? giftsDividedByCategories.value.premium
    : giftsDividedByCategories.value.basic;
});

function openGiftModal(gift: ExtractClassFields<DialogGift>): void {
  selectedGift.value = gift;
  virtualGiftModal.showModal();
}

function sendGift(gift: ExtractClassFields<DialogGift>) {
  emit('send', gift.id);
}

const virtualGiftModal = useModal(() => ({
  component: ChatBuyFreeGiftModal,
  props: {
    freeGift: selectedGift,
  },
  on: {
    upgrade: (): void => {
      virtualGiftModal.hideModal();

      const subscriptionModal = useModal(() => ({
        component: Purchase,
        props: {
          purchase: 'premium',
          close: () => {
            subscriptionModal.hideModal();
          },
        },
      }));

      subscriptionModal.showModal();
    },
    send: (gift: ExtractClassFields<DialogGift>): void => {
      virtualGiftModal.hideModal();
      sendGift(gift);
    },
  },
}));
</script>
